.b-search {
	&.b-search--result {
		position: relative;
		width: 100%;
		display: flex;

		.b-search__input {
			width: 100%;
			height: 100%;
			border: none;
			border-bottom: 1px solid $base-text-color;
			transition: border-color 0.2s ease;
			padding-right: 40px;

			&:focus {
				border-color: $project--main-color;
			}
		}

		.b-search__wrapper-input {
			display: block;
			width: 100%;
			margin-right: 30px;
		}

		.b-search__button {
			display: block;
			height: 100%;
			z-index: 1;
		}
	}
}
