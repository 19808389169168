$slider-nav--color: $color-white;
$slider-nav--color-hover: $color-celeste;
$slider-nav--color-active: $color-summer-sky;
$slider-nav--bg: transparent;
$slider-nav--bg-hover: transparent;
$slider-nav--bg-active: transparent;
$slider-pagination--color: $color-white;
$slider-pagination--color-hover: $color-white;
$slider-pagination--color-active: $color-white;
$slider-pagination--bg: $color-ironside-gray;
$slider-pagination--bg-hover: $color-black;
$slider-pagination--bg-active: orange;

// .body--slider-gallery {
	// .l-page__content {
	// 	width: 100%;
	// }

	// .l-layout-wrapper--1 .content-area {
	// 	padding: 0;
	// }

	// .l-layout-wrapper__page {
	// 	max-width: 100%;
	// 	width: 100%;
	// 	flex-basis: auto;
	// }

.b-slider-wrapper--gallery {
	position: relative;

	.l-slider-item__inner {
		max-width: $site-width;
		margin: auto;
	}

	.b-slider-item {
		background-repeat: no-repeat;
		background-size: cover;
		background-position: center center;
	}

	// .b-slider-item--0 {
	// 	background-image: url('/images/components/slider/images/bg--1.jpg');
	// }

	// .b-slider-item--1 {
	// 	background-image: url('/images/components/slider/images/bg--2.jpg');
	// }

	// .b-slider-item--2 {
	// 	background-image: url('/images/components/slider/images/bg--3.jpg');
	// }

	.l-slider-item__container {
		// padding: 20px 40px;
	}

	.b-slider-item__content {
		position: relative;
		// color: $color-white;
		// background-color: rgba(0, 0, 0, 0.7);
		// padding: 20px;
		// box-shadow: 0px 30px 40px -25px rgba(0, 0, 0, 1);

		*:last-child {
			margin-bottom: 0;
		}
	}

	.b-slider-item__image {
		width: 100%;
		height: 300px;
		object-fit: cover;
	}

	.b-slider-item__description,
	.l-slider-description-wrapper {
		position: absolute;
		bottom: 4px;
		width: 100%;
		background-color: rgba(0, 0, 0, 0.7);
		padding: 10px;
		z-index: 1;

		p {
			color: $color-white;
			font-style: italic;
		}

		*:last-child {
			margin-bottom: 0;
		}
	}

	.l-slider-button-wrapper {
		position: absolute;
		width: 100%;
		height: 0;
		left: 0;
		right: 0;
		top: 50%;
		transform: translateY(-50%);
		z-index: 1;
		max-width: $site-width;
		margin: auto;
	}

	.b-slider-button {
		position: absolute;
		top: 0;
		transform: translateY(-50%);
		background-color: $slider-nav--bg;
		color: $slider-nav--color;
		transition: color 0.2s ease, opacity 0.2s ease;

		&:before {
			@include font-size(30px);
			text-shadow: 1px 1px 4px $color-black;
		}

		&.swiper-button-disabled {
			opacity: 0.3;
		}

		&:not(.swiper-button-disabled) {
			cursor: pointer;

			&:hover {
				background-color: $slider-nav--bg-hover;
				color: $slider-nav--color-hover;
			}

			&:active {
				background-color: $slider-nav--bg-active;
				color: $slider-nav--color-active;
			}
		}
	}

	.b-slider-button--prev {
		left: 0;
		@include webfont-icon($webfont-icon--gallery-icon);

		&::before {
			transform: rotate(180deg);
		}
	}

	.b-slider-button--next {
		right: 0;
		@include webfont-icon($webfont-icon--gallery-icon);
	}

	.l-slider-pagination-wrapper {
		position: absolute;
		bottom: 10px;
		left: 0;
		right: 0;
		z-index: 1;
		width: 100%;
		height: 0;
		max-width: $site-width;
		margin: auto;
	}

	.b-slider-pagination {
		transform: translateY(-100%);
		float: right;

		&__item {
			@include rem(width, 20px);
			@include rem(height, 20px);
			@include rem(line-height, 20px);
			color: $slider-pagination--color;
			background-color: $slider-pagination--bg;
			text-align: center;
			opacity: 1;
			border-radius: 100%;
			transition: color 0.2s ease, background-color 0.2s ease;
			// outline: 0;

			&:hover {
				color: $slider-pagination--color-hover;
				background-color: $slider-pagination--bg-hover;
			}

			&:active,
			&.swiper-pagination-bullet-active {
				color: $slider-pagination--color-active;
				background-color: $slider-pagination--bg-active;
			}

			& + .b-slider-pagination__item {
				margin-left: 10px;
			}
		}
	}

	@media (max-width: $site-width + $grid-gutter-width * 2) {
		.b-slider-pagination {
			padding-right: $grid-gutter-width;
		}
	}
}
