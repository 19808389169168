.b-social--static-in-line {

	.b-social__item {
		display: inline-block;
		vertical-align: top;
		
		&:not(:last-child) {
			margin-right: 21px;
		}
	}

	.b-social__link,
	.b-social__button {
		display: block;
		background: transparent;
		width: 24px;
		height: 24px;
		// border-radius: 3px;
		// border: 1px solid rgba($color-white, 0.8);
		color: $color-white;
		text-decoration: none;

		&:hover {
			// background-color: $b-social-link-bg-hover;
			// color: $b-social-icon-hover;
			// border: 1px solid $color-white;
			color: $color-white;
			background: transparent;
		}

		&.is-expand,
		&:active {
			// background-color: $b-social-link-bg-active;
			border: 1px solid $color-white;
			color: $color-white;
			background: transparent;
		}

		&:before {
			position: absolute;
			top: 50%;
			left: 50%;
			transform: translate(-50%, -50%);
			@include font-size(24px);
		}
	}

	.b-social__list--lvl2 {
		position: absolute;
		white-space: nowrap;
		width: auto;
		display: none;
		bottom: 100%
	}

	.has-sublist {
		position: relative;
	}

	.b-social__item--lvl2 {
		display: block;
	}

	.b-social__link--lvl2 {
		background-color: red;
	}

	.b-social__link--twitter {
		@include webfont-icon($webfont-icon--twitter_sb);

		&:before {
			// @include font-size(13.5px);
		}
	}

	.b-social__link--facebook {
		@include webfont-icon($webfont-icon--facebook_sb);
	}

	.b-social__link--facebook-multiple {
		@include webfont-icon($webfont-icon--facebook_sb);
	}

	.b-social__link--facebook1 {
		@include webfont-icon($webfont-icon--facebook_sb);

		&:before {
			// @include font-size(19px);
		}
	}

	.b-social__link--facebook2 {
		@include webfont-icon($webfont-icon--facebook_sb);
	}

	.b-social__link--facebook3 {
		@include webfont-icon($webfont-icon--facebook_sb);
	}

	.b-social__link--linkedin {
		@include webfont-icon($webfont-icon--linkedin);
	}

	.b-social__link--vkontakte {
		@include webfont-icon($webfont-icon--vk_sb);

		&:before {
			// @include font-size(10.5px);
		}
	}

	.b-social__link--telegram {
		@include webfont-icon($webfont-icon--telegram);

		&:before {
			// @include font-size(10.5px);
		}
	}

	.b-social__link--youtube {
		@include webfont-icon($webfont-icon--youtube_sb);

		&:before {
			// @include font-size(11.5px);
		}
	}

	.b-social__link--instagram {
		@include webfont-icon($webfont-icon--instagram_sb);

		&:before {
			// @include font-size(15.5px);
		}
	}

	.b-social__link--foursquare {
		@include webfont-icon($webfont-icon--foursquare);
	}

	.b-social__link--ok {
		@include webfont-icon($webfont-icon--ok_sb);

		&:before {
			// @include font-size(18px);
		}
	}

	&.b-social--shareholders {
		display: flex;
   		align-items: center;

		.b-social--shareholders__title {
			margin-right: 10px;
			color: $color-white;
		}
	}
}

@if ($responsive) {
	@include breakpoint(xs) {
		.b-social .b-social__list {
			display: flex;
		}

		.b-social--static-in-line .b-social__item:not(:last-child) {
			margin-right: 24px;
		}

		.b-social--static-in-line {
			&.b-social--shareholders {
				.b-social--shareholders__title {
					@include font-size(12px);
					margin-right: 24px;
				}
			}
		}

		.b-social--static-in-line {
			.b-social__link,
			.b-social__button {
				width: 21px;
				height: 21px;

				&:before {
					@include font-size(21px);
				}
			}
		}
	}
}