.js-customScroll {
	max-height: 300px; // частный случай
	overflow: hidden;

	.os-host-resize-disabled.os-host-scrollbar-horizontal-hidden > .os-scrollbar-vertical {
		// os-scrollbar
		// os-scrollbar-vertical
		width: 8px;
		height: 100%;
	}

	.os-scrollbar-track {
		// подложка
		border: 1px solid $color-white !important;
		// +вариант без бордера с фоном
	}

	.os-scrollbar-handle {
		// таскалка: варировать цвет
		background-color: $color-white;

		cursor: grab;
	}
}
