$cookie-law--btn-color: $color-ironside-gray;
$cookie-law--btn-color-hover: $color-white;
$cookie-law--btn-bg: $color-celeste;
$cookie-law--btn-bg-hover: $color-tundora;
$cookie-law--btn-bg-active: $color-tundora;

@if ($contrast) {
	$cookie-law--btn-color: $color-ironside-gray;
	$cookie-law--btn-color-hover: $color-white;
	$cookie-law--btn-bg: $color-light-gray;
	$cookie-law--btn-bg-hover: $color-tundora;
	$cookie-law--btn-bg-active: $color-dark-jungle;
}

.b-noty--cookie-law {
	z-index: 10;

	.b-noty__buttons {
		display: block;
		@include rem(margin-top, 15px);
	}

	.b-cookie-law__btn {
		@include rem(width, 156px);
		@include rem(height, 50px);
		// color: $cookie-law--btn-color;
		// background-color: $cookie-law--btn-bg;

		&:hover {
			// color: $cookie-law--btn-color-hover;
			// background-color: $cookie-law--btn-bg-hover;
		}

		&:active {
			// color: $cookie-law--btn-color-hover;
			// background-color: $cookie-law--btn-bg-active;
		}
	}
}
