$b-social-link-size: 30px;
$b-social-icon-size: 15px;
$b-social-icon: $color-tundora;
$b-social-icon-hover: $color-white;
$b-social-link-bg: $color-white;
$b-social-link-bg-hover: $color-ironside-gray;
$b-social-link-bg-active: $color-mine-shaft;

@if ($contrast) {
	$b-social-icon: $color-ironside-gray;
	$b-social-icon-hover: $color-white;
	$b-social-link-bg: $color-eallery;
	$b-social-link-bg-hover: $color-tapa-gray;
	$b-social-link-bg-active: $color-mine-shaft;
}

.b-social {
	.b-social__list {
		font-size: 0;
		@extend .user-select--no;
	}

	.b-social__item {
		display: inline-block;
		vertical-align: top;
	}

	.b-social__link,
	.b-social__button {
		display: block;
		@include rem(width, $b-social-link-size);
		@include rem(height, $b-social-link-size);
		overflow: hidden;
		position: relative;
		background-color: $b-social-link-bg;
		transition: background-color 0.2s ease;

		&:hover {
			background-color: $b-social-link-bg-hover;
			color: $b-social-icon-hover;
		}

		&.is-expand,
		&:active {
			background-color: $b-social-link-bg-active;
		}

		&:before {
			position: absolute;
			top: 50%;
			left: 50%;
			transform: translate(-50%, -50%);
			@include font-size(18px);
			color: $b-social-icon;
		}
	}

	.b-social__list--lvl2 {
		position: absolute;
		white-space: nowrap;
		width: auto;
		display: none;
		bottom: 100%;
	}

	.has-sublist {
		position: relative;
	}

	.b-social__item--lvl2 {
		display: block;
	}

	.b-social__link--lvl2 {
		background-color: red;
	}

	.b-social__link--twitter {
		@include webfont-icon($webfont-icon--twitter);
	}

	.b-social__link--facebook {
		@include webfont-icon($webfont-icon--facebook);
	}

	.b-social__link--facebook-multiple {
		@include webfont-icon($webfont-icon--facebook);
	}

	.b-social__link--facebook1 {
		@include webfont-icon($webfont-icon--facebook);
	}

	.b-social__link--facebook2 {
		@include webfont-icon($webfont-icon--facebook);
	}

	.b-social__link--facebook3 {
		@include webfont-icon($webfont-icon--facebook);
	}

	.b-social__link--linkedin {
		@include webfont-icon($webfont-icon--linkedin);
	}

	.b-social__link--vkontakte {
		@include webfont-icon($webfont-icon--vk_2);
		@include font-size(16px);
	}

	.b-social__link--youtube {
		@include webfont-icon($webfont-icon--youtube_2);
	}

	.b-social__link--instagram {
		@include webfont-icon($webfont-icon--instagram);
	}

	.b-social__link--foursquare {
		@include webfont-icon($webfont-icon--foursquare);
	}
}

