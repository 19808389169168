.highcharts-menu {
	li {
		&::before {
			display: none;
		}
	}
}

.graphic--last-dark {
	.highcharts-series {
		.highcharts-point {
			&:last-child {
				fill: #00377B !important;
			}
		}
	}
	.highcharts-label {
		&:last-child {
			text {
				fill: #00377B !important;
			}
		}
	}
}

.highcharts-credits {
	display: none;
	opacity: 0;
	pointer-events: none;
}

.highcharts-title {
	opacity: 0;
}
// смотри JS файл. 59 строка
// .graphic--skew {
// 	.highcharts-series {
// 		.highcharts-point {
// 			transform: skew(0deg, -16deg);
// 		}
// 	}
// }
