.highlights {
    display: flex;
    flex-direction: column;

    &__month {
        display: flex;
        flex-wrap: nowrap;
        align-items: stretch;
        position: relative;
    }

    &__center {
        width: 144px;
        background-color: $color-dark-cerulean;
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;
        line-height: 144px;
        @include rem(padding, 24px 0);
        border-bottom: 1px solid $color-white;
    }

    &__side {
        width: calc((100% - 144px) / 2);
        @include rem(padding, 40px 0);
        display: flex;
        flex-direction: column;
        justify-content: center;

        &--left {
            @include rem(padding-right, 40px);
        }

        &--right {
            @include rem(padding-left, 40px);
        }

        &--bord {
            border-bottom: 1px solid $color-black;
        }
    }

    &__month-name {
        font-size: 64px;
        line-height: 64px;
        font-weight: bold;
        color: $color-white;
        display: block;

        &--left {
            // transform: rotate(90deg);
            writing-mode: vertical-rl;
        }

        &--right {
            // transform: rotate(-90deg);
            writing-mode: vertical-rl;
            transform: rotate(180deg);
        }
    }

    ul > li {
        @include rem(margin, 0);

        &:not(:last-child) {
            @include rem(margin, 0 0 40px);
        }

        &:before {
            background-color: $color-deep-sky-blue;
        }

        ul > li {
            @include rem(margin, 0);

            &:not(:last-child) {
                @include rem(margin, 0 0 16px);
            }

            &:before {
                content: '';
                background-color: $color-dark-cerulean;
                top: calc(0.4em * 1.4);
                height: 5px;
            }
        }
    }

    ul {
        @include rem(margin, 0);

        ul {
           @include rem(margin, 16px 0 0); 
        }
    }

    &__award {
        max-width: 540px;
        margin: auto;

        &--wide {
            max-width: 630px;
            margin: auto 0 20px;

            &:last-child {
                @include rem(margin, 20px 0 auto);
            }

            &:first-child {
                @include rem(margin, auto 0 0);
                .browser-ie & {
                    @include rem(margin, 0);
                }
            }
        }

        &--banner {
            max-width: 550px;
            margin: auto 0 auto 13px;
        }
    }

    &__award-inner {
        display: flex;
        flex-wrap: nowrap;
        align-items: center;
    }

    &__award-top {
        display: block;
        @include font-size(18px);
        @include rem(line-height, 24px);
        font-weight: 600;
        color: $color-deep-sky-blue;

        &--big {
            @include font-size(24px);
            @include rem(line-height, 32px);
        }
    }

    &__award-name {
        display: block;
        @include font-size(24px);
        @include rem(line-height, 32px);
        font-weight: bold;
        color: $color-deep-sky-blue;
        text-transform: uppercase;

        &--big {
            @include font-size(32px);
            @include rem(line-height, 42px);
        }
    }

    &__award-lead {
        display: block;
        @include font-size(20px);
        @include rem(line-height, 26px);
        font-weight: 600;
        color: $color-dark-cerulean;
    }

    &__award-icon {
        width: 157px;
        flex-shrink: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-shrink: 0;
        color: $color-deep-sky-blue;

        &--trophy {
            @include webfont-icon($webfont-icon--c-trophy);
            @include font-size(104px);
        }

        &--clip {
            @include webfont-icon($webfont-icon--c-clip);
            @include font-size(75px);
        }

        &--list {
            @include webfont-icon($webfont-icon--c-list);
            @include font-size(79px);
        }

        &--mobile {
            @include webfont-icon($webfont-icon--c-mobile);
            @include font-size(74px);
        }

        &--medal {
            @include webfont-icon($webfont-icon--c-medal);
            @include font-size(64px);
        }

        &--vr {
            @include webfont-icon($webfont-icon--c-vr);
            @include font-size(87px);
        }

        &--comp {
            @include webfont-icon($webfont-icon--c-comp);
            @include font-size(79px);
        }
    }

    &__award-description {
        // width: calc(100% - 157px);
    }

    &__award-note {
        margin-top: 24px;
        border-top: 1px solid $color-deep-sky-blue;

        & > * {
            display: block;
        }
    }

    &__award-banner {
        max-width: 438px;
        @include rem(margin, 54px 0 30px);

        .browser-ie & {
            margin: 54px 0 30px !important;
        }

        & + .highlights__award-description {
            max-width: 438px; 
        }
    }

    &__value {
        @include font-size(48px);
    }

    @include breakpoint(md) {

        &__award-icon {
            width: 105px;
        }

        &__center {
            width: 90px;
        }

        &__side {
            width: calc((100% - 90px) / 2);

            &--left {
                @include rem(padding-right, 25px);
            }

            &--right {
                @include rem(padding-left, 25px);
            }
        }
    }

    @include breakpoint(sm) {

        &__month {
            flex-direction: column;
            @include rem(padding, 16px 26px 16px 0);
            border-bottom: 1px solid $color-black;

            &:last-child {
                border: none;
                padding-bottom: 0;
            }

            &:nth-child(2) {
                padding-top: 0;
            }
        }

        &__center {
            position: absolute;
            top: 0;
            right: 0;
            width: 26px;
            line-height: 26px;
            @include rem(padding, 16px 0);
            height: calc(100% + 1px);
            align-items: flex-start;
        }

        &__side {
            width: 100%;
            padding: 0;

            &--left {
                padding-right: 8px;
            }

            &--right {
                padding-right: 8px;
                padding-left: 0;
            }

            &--bord {
                border: none;
            }

            &--empty {
                display: none;
            }

            &--np {
                padding-top: 0;
            }

            &--1 {
                order: 1;
                margin-bottom: 24px;
            }

            &--2 {
                order: 2;
            }
        }

        &__month-name {
            @include font-size(26px);

            &--right {
                transform: none;
            }
        }

        ul > li {

            &:not(:last-child) {
                @include rem(margin, 0 0 16px);
            }

            ul > li {

                &:not(:last-child) {
                    @include rem(margin, 0 0 8px);
                }
            }
        }

        &__award-top {
            @include font-size(12px);
            @include rem(line-height, 16px);
        }

        &__award-name {
            @include font-size(18px);
            @include rem(line-height, 24px);
        }

        &__award-lead {
            @include font-size(14px);
            @include rem(line-height, 18px);
        }

        &__award {
            max-width: 452px;
            margin: 0;

            & + & {
                margin-top: 16px;
            }
        }

        &__award-icon {
            width: 75px;

            &--trophy {
                @include font-size(68px); 
            }

            &--clip {
                @include font-size(49px); 
            }

            &--comp,
            &--list {
                @include font-size(51px);
            }

            &--mobile {
                @include font-size(48px);
            }

            &--medal {
                @include font-size(42px);
            }

            &--vr {
                @include font-size(57px);
            }
        }

        &__award-banner {
            @include rem(margin, 24px 0 16px);

            .browser-ie & {
                margin: 24px 0 16px !important;
            }
        }
    }
}