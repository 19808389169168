.button--excel {
	color: $tools--color;
	display: inline-flex;
	background-color: transparent;
	@include webfont-icon($webfont-icon--i-xls);
	font-size: 10px;
	@include rem(line-height, 11px);
	@extend %webfont-button-animation; /* /src/styles/styles/utilities.scss */


	&:before {
		font-size: 13px;
		margin-right: 6px;
	}

	&:hover {
		color: $tools--color-hover;
	}

	&:active {
		color: $tools--color-active;
	}

}

.button--show-only-in-mobile {
	display: none;
}

@if ($responsive) {
	@include breakpoint(sm) {
		.button--show-only-in-mobile {
			display: initial;
		}
	}
}

.download-arrow {
	position: fixed;
	z-index: 999;
	display: none;
	height: 120px;
	width: 94px;
	opacity: 0;
	@include svg(94px, 120px, '/images/icons/arrow-download-xls.svg');
	background-repeat: no-repeat;
	background-position-x: 0;
	background-position-y: 0;
}

.download-arrow--ie8 {
	top: 10px;
	left: 20px;
	filter: progid:DXImageTransform.Microsoft.Alpha(opacity=0) progid:DXImageTransform.Microsoft.AlphaImageLoader(src="https://i.imgur.com/aMwoyfN.png", sizingMethod="scale") progid:DXImageTransform.Microsoft.matrix(M11=1, M12=1.2246063538223773e-16, M21=-1.2246063538223773e-16, M22=-1, SizingMethod="auto expand");
}

.download-arrow--ie9 {
	bottom: 50px;
	left: 67%;
}

.download-arrow--chrome {
	bottom: 30px;
	left: 20px;

	.browser-ie &,
	.browser-yabrowser,
	.browser-opera {
		bottom: auto;
		left: auto;
		top: 30px;
		right: 20px;
	}
}

.download-arrow--safari {
	top: 0px;
	right: 100px;
	transform: rotateX(180deg) rotateY(180deg);
}

.download-arrow--firefox {
	top: 0px;
	right: 65px;
	transform: rotateX(180deg) rotateY(180deg);
}

.browser-yabrowser,
.browser-opera {
	.download-arrow--chrome {
		top: 0px;
		right: 0px;
		left: auto;
		bottom: auto;
		transform: rotateX(180deg) rotateY(180deg);
	}
}
