$menu_vertical-accordeon--border: #e2e8ec;

@if ($contrast) {
	$menu_vertical-accordeon--border: #ededed;
}

.b-nav--left.b-nav--vertical-accordeon {
	.b-nav__list {
		.b-nav__list {
			display: none;
		}

		&.is-expand {
			display: block;
		}
	}

	.b-nav__link {
		position: relative;
		color: $menu_vertical--link-color;
		@include font-size($menu_vertical--font-size);
		text-decoration: none;
		line-height: 1;

		span {
			line-height: 1.4;
		}

		&:hover {
			color: $menu_vertical--link-color-expand-hover;
		}

		&.current {
			color: $menu_vertical--link-color-current;
		}
	}

	.has-submenu {
		.icon-left-nav {
			position: absolute;
			@include rem(width, 25px);
			top: 0;
			bottom: 0;
			@include rem(left, -25px);

			&:before,
			&:after {
				content: '';
				position: absolute;
				display: block;
				@include rem(width, 2px);
				@include rem(height, 10px);
				top: 0;
				right: 0;
				bottom: 0;
				left: 0;
				margin: auto;
				background-color: $menu_vertical--link-color;
				transition: transform 0.2s ease;
				@include rem(border-radius, 5px);
			}

			&:before {
				transform: rotate(0deg);
			}

			&:after {
				transform: rotate(90deg);
			}

			&:hover {
				&:before,
				&:after {
					background-color: $menu_vertical--link-color-expand-hover;
				}
			}
		}

		&.is-expand {
			.icon-left-nav {
				&:before {
					transform: rotate(90deg);
				}
			}
		}
	}

	.b-nav__item--lvl1 > .b-nav__link-wrapper {
		@include rem(padding-left, 25px);
	}

	.b-nav__item--lvl2 > .b-nav__link-wrapper {
		@include rem(padding-left, 45px);
	}

	.b-nav__item--lvl3 > .b-nav__link-wrapper {
		@include rem(padding-left, 65px);
	}

	.b-nav__link-wrapper {
		@include rem(padding-right, 30px);
		@include rem(border-bottom-width, 1px);
		border-bottom-style: solid;
		border-bottom-color: $menu_vertical-accordeon--border;
		@include rem(padding-top, 5px);
		@include rem(padding-bottom, 5px);
	}
}
