$button-width: 33.333%;
$icon-side-margin: 5px;
$button-side-padding: 10px;

// Стили для случая, когда page-nav зафиксирован при скролле
.b-fixed-area {
	@extend %wrapper;
	// position: fixed;
	bottom: 0;
	left: 0;
	z-index: 10;
	text-align: center;
}

// .b-fixed-area--above-footer {
// 	bottom: $footer-height;
// }

// Стили для случая, когда page-nav находится внизу страницы
.b-now-static-area {
	position: relative;
	width: 100%;
	margin-bottom: 21px;
}

.b-pagenav--bottom {
	background-color: $color-white;
	padding-bottom: 21px;
	.b-pagenav {

		&__centred {
			color: #28AAE1;
			position: relative;
			@extend %clearfix;
		}

		&__link-content-wrapper {
			display: flex;
			align-items: center;
			width: 100%;
			text-overflow: ellipsis;
			white-space: nowrap;
			overflow: hidden;
		}

		&__link {
			display: flex;
			align-items: center;
			position: relative;
			line-height: 0;
			background-color: transparent;
			transition: background-color 0.2s ease;
			width: $button-width;
			text-decoration: none;
			@include font-size(14px);
			color: #28AAE1;
			line-height: 38px;
			height: 37.6px;

			.b-pagenav__text {
				opacity: 0;
				display: block;
			}

			&:before {
				@include font-size(18px);
			}

			&:hover,
			&.is-touch {
				background-color: $pagenav--btn-bg-hover;
				color: $pagenav--icon-color-hover;

				.b-pagenav__title {
					left: 0;
					opacity: 1;
				}

				.b-pagenav__text {
					color: $pagenav--icon-color-hover;
					opacity: 1;
				}

				.b-icon {
					border: 1px solid $pagenav--icon-color-hover;
				}
			}

			&:active {
				background-color: $pagenav--btn-bg-active;
				color: $pagenav--icon-color-active;

				.b-pagenav__text {
					color: $pagenav--icon-color-active;
					opacity: 1;
				}

				.b-icon {
					border: 1px solid $pagenav--icon-color-active;
				}
			}

			.b-icon--prev {
				@include rem(margin-right, 20px);
			}

			.b-icon--next {
				@include rem(margin-left, 20px);
			}

			.b-icon {
				display: flex;
				align-items: center;
				justify-content: center;
				width: 31px;
				height: 31px;
				min-width: 31px;
				border: 1px solid #28AAE1;
				border-radius: 3px;
			}
		}

		&__text {
			max-width: 83%;
			overflow: hidden;
			@include breakpoint(xs) {
                display: none !important;
            }
		}

		&__scrollup-wrapper {
			@extend %center-position;
			width: $button-width;
			background-color: $pagenav--btn-bg;

			.b-icon--scrollup {
				@include font-size(14px);
				@include webfont-icon($webfont-icon--nav-top);
				position: relative;
				margin: auto;
				left: 0;
				top: 0;
				right: 0;
			}

			.b-pagenav__scrollup {
				@extend .b-pagenav__link;
				text-align: center;
				width: 100%;
				cursor: pointer;
				color: #28AAE1;
				height: 37.6px;

				.b-pagenav__text--scrollup {
					display: none;
					position: absolute;
					top: 50%;
					left: 50%;
					transform: translate(-50%, -50%);
					opacity: 0;
				}

				&:hover,
				&.is-touch {
					color: $pagenav--icon-color-hover;

					.b-icon--scrollup {
						border: 1px solid $pagenav--icon-color-hover;
					}
				}

				&:active {
					color: $pagenav--icon-color-active;

					.b-icon--scrollup {
						border: 1px solid $pagenav--icon-color-active;
					}
				}

				&:active:hover {
					margin: 0;
					padding: 0;
				}
			}
		}

		&__link--prev {
			float: left;
			text-align: left;
			@include rem(padding-left, $grid-gutter-width);
			@include rem(padding-right, $button-side-padding);

			.b-icon--prev {
				@include webfont-icon($webfont-icon--nav-prev);
			}
		}

		&__link--next {
			float: right;
			text-align: right;
			@include rem(padding-left, $button-side-padding);
			@include rem(padding-right, $grid-gutter-width);

			.b-icon--next {
				order: 1;
				@include webfont-icon($webfont-icon--nav-next);
			}

			.b-pagenav__link-content-wrapper {
				justify-content: flex-end;
			}
		}

		&__title {
			display: block;
			position: absolute;
			width: 100%;
			@include rem(bottom, $pagenav--link-height);
			left: -9999px;
			line-height: $base-line-height;
			color: $pagenav--title-color;
			background-color: $pagenav--title-bg;
			@include font-size(13px);
			margin: 0;
			@include rem(padding, 15px);
			opacity: 0;
			transition: opacity 0.2s ease;

			.b-pagenav-help {
				display: block;
			}
		}

		// Стили, применяемые к неактивным кнопкам пагинации
		&__link--disabled {
			opacity: 0.3;
			pointer-events: none;
			background-color: $pagenav--btn-bg;
			cursor: default;
			outline: 0;

			&:hover {
				background-color: $pagenav--btn-bg;

				.b-pagenav__title {
					left: -9999px;
					opacity: 0;
				}
			}

			&:active {
				background-color: $pagenav--btn-bg;
			}
		}
	}
}

.b-pagenav--animate-links {
	.b-pagenav {
		&__link-content-wrapper {
			height: 100%;
			position: relative;
			overflow: visible;

			.b-icon {
				display: flex;
				align-items: center;
				justify-content: center;
			}
		}

		&__scrollup-wrapper {
			.b-pagenav__scrollup {
				height: 100%;
			}

			.b-icon--scrollup {
				@include rem(bottom, 20px);
			}
		}

		&__direction {
			display: block;
			@include font-size(25px);
			position: relative;
			@include rem(top, 13px);
			transition: all 0.4s ease-in-out;
		}

		&__text {
			@include rem(bottom, -25px);
			transition: all 0.4s ease-in-out;
			overflow: hidden;
			white-space: nowrap;
			text-overflow: ellipsis;
			width: 100%;
			position: absolute;

		}

		&__text--scrollup {
			left: 0;
			bottom: 0;
		}

		&__link {
			@include rem(height, $pagenav--link-height * 2);
			overflow: hidden;

			.b-icon--prev {
				@include rem(left, -45px);
				transition: all 0.4s ease-in-out;
			}

			.b-icon--next {
				@include rem(right, -45px);
				transition: all 0.4s ease-in-out;
			}

			&:hover {
				.b-icon--prev {
					@include rem(left, -20px);
				}

				.b-icon--next {
					@include rem(right, -20px);
				}

				.b-pagenav__direction {
					@include font-size(16px);
					@include rem(top, 2px);
				}

				.b-pagenav__text {
					bottom: 0;
				}

				.b-pagenav__text--next {
					left: 0;
				}
			}
		}
	}
}

.b-pagenav--loading {
	.b-pagenav__scrollup-wrapper {
		display: none;
	}

	.b-pagenav__link {
		width: 50%;
	}
}

.pagenav--hidden-up {
	.b-pagenav__scrollup-wrapper {
		display: none;
	}

	.b-pagenav__link {
		width: 50%;
	}
}

.b-pagenav--expandable {
	.b-pagenav {
		&__link {
			width: 50%;
		}

		&__expandable-button {
			@include webfont-icon($webfont-icon--menu);
			background-color: $pagenav--btn-bg;
			width: 40px;
			border-radius: 50%;
			transform: translateY(17px);
			color: $color-taupe-gray;
			@include rem(padding-bottom, 15px);

			&:hover,
			&:active {
				color: $color-taupe-gray;
			}
		}

		.b-icon {
			position: absolute;
		}

		&__link--disabled {
			opacity: 1;
			.b-icon {
				display: none;
			}
		}
	}

	.is-open {
		transition: all 0.4s ease-in-out;
		bottom: 0;
	}

	.is-close {
		transition: all 0.4s ease-in-out;
		@include rem(bottom, -24px);
	}
}

@if ($responsive) {
	@include breakpoint(sm) {
		.b-pagenav--animate-links {
			.b-pagenav {
				&__scrollup-wrapper {
					.b-pagenav__scrollup {
						height: 100%;
					}

					.b-icon--scrollup {
						bottom: 0;
					}
				}

				&__direction {
					display: none;
				}

				&__text {
					display: none;
				}

				&__link {
					@include rem(height, $pagenav--link-height);
					.b-icon--prev {
						@include rem(left, 10px);
					}

					.b-icon--next {
						@include rem(right, 10px);
					}
				}
			}
		}
	}
}
