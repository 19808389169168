.b-search-result {
	margin-top: 1.6em;

	&__item {
		padding-top: $grid-gutter-width;
		@include rem(border-top-width, 1px);
		border-top-style: solid;

		&:first-child {
			padding-top: 0;
			border-top: 0;
		}
	}

	&__title {
		@include font-size(18px);
	}

	&__link {
		font-weight: 700;
		color: $link-default-color;
		text-decoration: none;

		&:visited {
			color: $link-visited-color;
		}

		&:hover,
		&:visited:hover {
			color: $link-hover-color;
		}

		&:active,
		&:visited:active {
			color: $link-active-color;
		}
	}

	&__loading-trigger {
		width: 100%;

		&.is-finidhed {
			display: none;
		}

		&.is-loading {
			height: 40px;
		}
	}
}
