$label-height: 30px;
$extendable-input-width: 500px;
$extendable-button-width: 30px;
$extendable-button-height: 30px;

.b-search {
	&.b-search--inner {
		position: relative;
		display: inline-block;
		width: 100%;
		@include rem(height, $label-height);
		@include rem(margin-bottom,15px);

		.b-search__label {
			position: absolute;
			color: $search--label-color;
			margin: auto;
			top: 0;
			bottom: 0;
			@include rem(height, 20px);
			@include rem(left, 30px);
			cursor: text;
		}

		.b-search__input {
			display: block;
			width: 100%;
			border: 1px solid #cccccc;
			@include rem(padding-left, 30px);
			border-radius: 0;
			-webkit-appearance: none;
		}

		.b-search__message {
			z-index: 1;
		}

		.b-search__button {
			display: block;
			position: absolute;
			top: 0;
			height: 100%;
			background-color: transparent;
			color: $search--btn-color;
			overflow: hidden;
			@include rem(width, 30px);
			@include webfont-icon($webfont-icon--search);
			@include font-size(15px);
			line-height: 1;
			padding: 0;

			&:hover {
				color: $search--btn-icon-hover;
			}

			&:active {
				color: $search--btn-icon-active;
			}
		}
	}

	.b-search__result {
		@include rem(top, $label-height);
	}
}
