$buttons-panel--trigger-icon-size: 22px;
$buttons-panel--trigger-text-font-size: 15px;
$buttons-panel--trigger-color: $color-dark-cerulean;
$buttons-panel--trigger-color-hover: $color-deep-sky-blue;
$buttons-panel--trigger-color-active: $color-dark-cerulean;

$buttons-panel--list-bg-color: $color-white;

$buttons-panel--link-icon-font-size: 22px;
$buttons-panel--link-font-size: 16px;
$buttons-panel--icon-color: $color-air-force-blue;
$buttons-panel--icon-color-hover: $color-dark-cerulean;
$buttons-panel--icon-color-active: $color-bright-grey;
$buttons-panel--link-color: $color-air-force-blue;
$buttons-panel--link-color-hover: $color-dark-cerulean;
$buttons-panel--link-color-active: $color-bright-grey;
$buttons-panel--link-bg-color: transparent;
$buttons-panel--link-bg-color-hover: transparent;
$buttons-panel--link-bg-color-active: transparent;

$buttons-panel--counter-width: 12px;
$buttons-panel--counter-height: 12px;
$buttons-panel--counter-color: $buttons-panel--link-color;
$buttons-panel--counter-color-hover: $buttons-panel--link-color-hover;
$buttons-panel--counter-color-active: $buttons-panel--link-color-active;
$buttons-panel--counter-bg-color: $buttons-panel--link-bg-color;
$buttons-panel--counter-bg-color-hover: $buttons-panel--link-bg-color-hover;
$buttons-panel--counter-bg-color-active: $buttons-panel--link-bg-color-active;
$buttons-panel--counter-border-color: $buttons-panel--counter-color;
$buttons-panel--counter-border-color-hover: $buttons-panel--counter-color-hover;
$buttons-panel--counter-border-color-active: $buttons-panel--counter-color-active;

$buttons-panel--sublist-bg-color: transparent;
$buttons-panel--sublist-icon-font-size: 13px;
$buttons-panel--sublist-link-width: 30px;
$buttons-panel--sublist-link-height: 30px;
$buttons-panel--sublist-link-icon-color: $color-air-force-blue;
$buttons-panel--sublist-link-icon-color-hover: $color-dark-cerulean;
$buttons-panel--sublist-link-icon-color-active: $color-bright-grey;
$buttons-panel--sublist-icon-bg-color: transparent;
$buttons-panel--sublist-icon-bg-color-hover: transparent;
$buttons-panel--sublist-icon-bg-color-active: transparent;

$buttons-panel--compare-link-font-size: 14px;

$buttons-panel--tooltips-color: $color-white;
$buttons-panel--tooltips-bg-color: $color-ironside-gray;

$buttons-panel--title-font-size: 18px;
$buttons-panel--title-color: $base-text-color;

@if ($contrast) {
	$buttons-panel--trigger-color: $color-ironside-gray;
	$buttons-panel--trigger-color-hover: $color-black;
	$buttons-panel--trigger-color-active: orange;

	$buttons-panel--list-bg-color: $color-eallery;

	$buttons-panel--link-color: $base-text-color;
	$buttons-panel--link-color-hover: $color-white;
	$buttons-panel--link-color-active: orange;
	$buttons-panel--link-bg-color: $buttons-panel--list-bg-color;
	$buttons-panel--link-bg-color-hover: #444444;
	$buttons-panel--link-bg-color-active: #444444;

	$buttons-panel--counter-color: $buttons-panel--link-color;
	$buttons-panel--counter-color-hover: $buttons-panel--link-color-hover;
	$buttons-panel--counter-color-active: $buttons-panel--link-color-active;
	$buttons-panel--counter-bg-color: $buttons-panel--link-bg-color;
	$buttons-panel--counter-bg-color-hover: $buttons-panel--link-bg-color-hover;
	$buttons-panel--counter-bg-color-active: $buttons-panel--link-bg-color-active;
	$buttons-panel--counter-border-color: $buttons-panel--counter-color;
	$buttons-panel--counter-border-color-hover: $buttons-panel--counter-color-hover;
	$buttons-panel--counter-border-color-active: $buttons-panel--counter-color-active;

	$buttons-panel--sublist-bg: $color-ironside-gray;

	$buttons-panel--sublist-bg-color: $color-ironside-gray;
	$buttons-panel--sublist-link-icon-color: $color-white;
	$buttons-panel--sublist-link-icon-color-hover: $color-gray;
	$buttons-panel--sublist-link-icon-color-active: orange;
	$buttons-panel--sublist-icon-bg-color: transparent;
	$buttons-panel--sublist-icon-bg-color-hover: transparent;
	$buttons-panel--sublist-icon-bg-color-active: transparent;

	$buttons-panel--tooltips-bg-color: $color-ironside-gray;
	$buttons-panel--tooltips-color: $color-white;

	$buttons-panel--title-color: $base-text-color;
}

%counter {
	// position: absolute;
	// display: block;
	// @include font-size(9px);
	// @include font(600);
	// text-align: center;
	// @include rem(width, $buttons-panel--counter-width);
	// @include rem(height, $buttons-panel--counter-height);
	// color: $buttons-panel--counter-color;
	// background-color: $buttons-panel--counter-bg-color;
	// border-width: 1px;
	// border-style: solid;
	// border-color: $buttons-panel--counter-border-color;
	// border-radius: 50%;
	// @include rem(top, 12px);
	// @include rem(left, 25px);
	@extend .user-select--no;
}

.b-buttons-panel__link {
	position: relative;
	display: block;
	text-decoration: none;
	line-height: 1.2;
	background-color: $buttons-panel--link-bg-color;
	@include font-size($buttons-panel--link-font-size);
	color: $buttons-panel--link-color;

	&:before {
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
		@include font-size(18px);
		color: $buttons-panel--icon-color;
	}

	&:visited {
		color: $buttons-panel--link-color;
		&:before {
			color: $buttons-panel--icon-color;
		}
	}

	&:hover,
	&:visited:hover {
		background-color: $buttons-panel--link-bg-color-hover;
		color: $buttons-panel--link-color-hover;
		text-decoration: none;
		&:before {
			color: $buttons-panel--icon-color-hover;
		}
	}

	&:active,
	&.is-expand,
	&:visited:active {
		background-color: $buttons-panel--link-bg-color-active;
		color: $buttons-panel--link-color-active;
		text-decoration: none;
		&:before {
			color: $buttons-panel--icon-color-active;
		}
	}

	&.is-visible {
		display: block;
	}

	//Мой отчет
	&--myreport {
		@include webfont-icon($webfont-icon--report_2);

		.b-buttons-panel__counter {
			@extend %counter;
		}

		&:hover {
			.b-buttons-panel__counter {
				color: $buttons-panel--counter-color-hover;
				background-color: $buttons-panel--counter-bg-color-hover;
				border-color: $buttons-panel--counter-border-color-hover;
			}
		}

		&:active {
			.b-buttons-panel__counter {
				color: $buttons-panel--counter-color-active;
				background-color: $buttons-panel--counter-bg-color-active;
				border-color: $buttons-panel--counter-border-color-active;
			}
		}
	}

	&--add,
	&--del {
		display: none;

		// &:after {
		// 	@extend %counter;
		// }

		&:hover {
			&:after {
				color: $buttons-panel--counter-color-hover;
				background-color: $buttons-panel--counter-bg-color-hover;
				border-color: $buttons-panel--counter-border-color-hover;
			}
		}

		&:active {
			&:after {
				color: $buttons-panel--counter-color-active;
				background-color: $buttons-panel--counter-bg-color-active;
				border-color: $buttons-panel--counter-border-color-active;
			}
		}
	}

	//Добавить в мой отчет
	&--add {
		@include webfont-icon($webfont-icon--add_2);

		// &:after {
		// 	content: '+';
		// }
	}

	//Удалить из моего отчета
	&--del {
		@include webfont-icon($webfont-icon--delete_2);

		// &:after {
		// 	content: '-';
		// }
	}

	//Печать страницы
	&--print {
		@include webfont-icon($webfont-icon--print_2);


	}

	//Скачать PDF
	&--pdf {
		@include webfont-icon($webfont-icon--download_2);

		&:before {
			@include font-size(20px);
		}
	}

	//Поделится в соц. сетях или по e-mail
	&--share {
		@include webfont-icon($webfont-icon--share_2);

		&:before {
			@include font-size(12px);
		}

		&.is-expand {
			padding-left: 5px!important;

			&:before {
				display: none;
			}
		}
	}

	//История просмотренных страниц
	&--history {
		@include webfont-icon($webfont-icon--history_2);
	}

	//Обратня связь
	&--feedback {
		@include webfont-icon($webfont-icon--feedback_2);

		&:before {
			
		}
	}

	//Центр загрузки
	&--download {
		@include webfont-icon($webfont-icon--center_2);
	}

	&--inter-map {
		@include webfont-icon($webfont-icon--map_2);
	}

	//Интерактивный анализ
	&--analysis {
		@include webfont-icon($webfont-icon--analyse);
	}

	//Сравнить с прошлым отчетом
	&--compare {
		@include webfont-icon($webfont-icon--books);
	}

	//Смотреть предыдущие отчеты
	&--see {
		@include webfont-icon($webfont-icon--eye);
	}

	//предыдущие отчеты
	&--previous-reports {
		@include webfont-icon($webfont-icon--books);
	}

	//Карта сайта
	&--site-map {
		@include webfont-icon($webfont-icon--tree);
	}

	//глосарий
	&--glossary {
		@include webfont-icon($webfont-icon--library);
	}

	//архив отчетов
	&--archive-reports {
		@include webfont-icon($webfont-icon--box-add);
	}

	//disclaimer
	&--disclaimer {
		@include webfont-icon($webfont-icon--file-text);
	}
}

//Стили для sublist (share, social, compare, etc.)
.b-buttons-panel__sublist {
	display: none;
	background-color: $buttons-panel--sublist-bg-color;
}

.b-buttons-panel__sublist-inner {
	width: 100%;
	// padding-left: 37px;
	display: flex;
}

.b-buttons-panel__sublist-link {
	position: relative;
	display: block;
	text-decoration: none;
	color: $buttons-panel--sublist-link-icon-color;
	background-color: $buttons-panel--sublist-icon-bg-color;
	@include rem(width, $buttons-panel--sublist-link-width);
	@include rem(height, $buttons-panel--sublist-link-height);

	&:before {
		position: absolute;
		left: 50%;
		top: 50%;
		transform: translate(-50%, -50%);
		@include font-size($buttons-panel--sublist-icon-font-size);
	}

	&:hover,
	&:visited:hover {
		color: $buttons-panel--sublist-link-icon-color-hover;
		background-color: $buttons-panel--sublist-icon-bg-color-hover;
	}

	&:active,
	&:visited:active {
		color: $buttons-panel--sublist-link-icon-color-active;
		background-color: $buttons-panel--sublist-icon-bg-color-active;
	}

	&--vkontakte {
		@include webfont-icon($webfont-icon--vk_3);

		&:hover,
		&:active {
			@include webfont-icon($webfont-icon--vk);
		}

		&:before {
			@include font-size(9px);
		}
	}

	&--linkedin {
		@include webfont-icon($webfont-icon--linkedin);
	}

	&--twitter {
		@include webfont-icon($webfont-icon--tw);

		&:hover,
		&:active {
			@include webfont-icon($webfont-icon--twitter);
		}

		&:before {
			@include font-size(11px);
		}
	}

	&--facebook {
		@include webfont-icon($webfont-icon--fb_3);
		width: $buttons-panel--sublist-link-width - 5;

		&:hover,
		&:active {
			@include webfont-icon($webfont-icon--facebook);
		}

		&:before {
			@include font-size(15px);
		}
	}

	&--youtube {
		@include webfont-icon($webfont-icon--youtube);
	}

	&--googleplus {
		@include webfont-icon($webfont-icon--google-plus);

		&:before {
			@include font-size(13px);
		}
	}

	&--email {
		@include webfont-icon($webfont-icon--mail);

		&:before {
			@include font-size(12px);
		}
	}
}

.b-buttons-panel__sublist-link--compare {
	white-space: nowrap;
	float: none;
	width: auto;
	height: auto;
	@include rem(padding, 5px 10px);
	@include font-size($buttons-panel--compare-link-font-size);
}

.tooltipster-sidetip.tooltipster-buttons-panel {
	.tooltipster-box {
		border: none;
		background: $buttons-panel--tooltips-bg-color;
		color: $buttons-panel--tooltips-color;
		@include font-size(15px);
		@include font(400);
		@include rem(border-radius, 5px);
	}

	.tooltipster-content {
		color: $buttons-panel--tooltips-color;
	}


	&.tooltipster-bottom .tooltipster-arrow-background {
		border-bottom-color: $buttons-panel--tooltips-bg-color;
	}

	&.tooltipster-left .tooltipster-arrow-background {
		border-left-color: $buttons-panel--tooltips-bg-color;
	}

	&.tooltipster-right .tooltipster-arrow-background {
		border-right-color: $buttons-panel--tooltips-bg-color;
	}

	&.tooltipster-top .tooltipster-arrow-background {
		border-top-color: $buttons-panel--tooltips-bg-color;
	}

	.tooltipster-arrow-border {
		border: none;
	}

	&.tooltipster-bottom .tooltipster-arrow-uncropped {
		top: -11px;
	}

	&.tooltipster-right .tooltipster-arrow-uncropped {
		left: -11px;
	}
}
