// Цвет фона фиксированной шапки
$table--fixed-head-bg: $color-white;
// Шапка
$table--header-color: $color-white;
$table--header-bg: $color-dark-cerulean;
// Подзаголовок
$table--subhead-color: $color-navy-blue;
// Выделенная ячейка
$table--strong-cell-color: $color-dark-cerulean;
$table--strong-cell-bg: $color-white;
$table--strong-cell-border: $color-white;
// Строка итогов
$table--summary-bg: $color-white;
$table--summary-color: $color-bright-grey;
$table--summary-hover: $color-alice-blue;
$table--summary-active-js: $color-alice-blue;
// Фон строки по наведению
$table-row-bg-hover: rgba($color-alice-blue, 0.35);
$table-row-bg-strong-hover: rgba($color-alice-blue, 0.35);
// Выделенная строка
$table--strong-row-color: $color-dark-cerulean;
$table--strong-row-bg: $color-white;
$table--strong-row-bg-hover: $color-alice-blue;
$table--strong-row-bg-js: $table--strong-row-bg-hover;
// Выделенная строка (при клике)
$table--selected-row-bg: rgba($color-pacific-blue, 0.12);
$table--selected-row-bg-strong: rgba($color-pacific-blue, 0.12);

@if ($contrast) {
	$table--header-color: $color-white;
	$table--header-bg: $color-mine-shaft;
	$table--strong-cell-color: $color-taupe-gray;
	$table--strong-cell-bg: $color-eallery;
	$table--strong-cell-border: $color-davys-grey;
	$table--subhead-color: $color-error;
	$table--summary-bg: $color-dark-jungle;
	$table--summary-color: $color-white;
	$table--fixed-head-bg: $color-tapa-gray;
}

table {
	border-collapse: collapse;
	border-spacing: 0;

	td,
	th {
		padding: 0;
	}

	.a_l {
		text-align: left !important;
	}

	.a_c {
		text-align: center !important;
	}

	.a_r {
		text-align: right !important;
	}

	.a_t {
		vertical-align: top !important;
	}

	.a_m {
		vertical-align: middle !important;
	}

	.a_b {
		vertical-align: bottom !important;
	}
}

// Стили для финансовой таблицы (стандартной)
.b-table {
	margin-bottom: 57px;

	@include breakpoint(md) {
		overflow-y: hidden;
		overflow-x: auto;
	}
	figcaption {
		@include font(600, 'VTBGroup');
		@include font-size(16px);
		line-height: 22px;
		text-transform: uppercase;
		color: $color-manatee;
		// border-bottom: 1px solid $color-deep-sky-blue;
		// padding-bottom: 8px;
		margin-bottom: 26px;

		.caption__units,
		.b-caption__units {
			font-weight: normal;
		}
	}

	table {
		min-width: 100%;

		td,
		th {
			padding: 0;
			border-right: 1px solid $color-white;
			position: relative;
			@include rem(padding, 10px 20px 10px 16px);
			text-align: center;
			vertical-align: bottom;

			&:last-child {
				@include rem(padding, 10px 20px 10px 16px);
			}
			&:first-child {
				@include rem(padding, 10px 20px 10px 11px);
			}

			&.stat {
				@include rem(padding, 10px 30px 10px 16px);

				&--down {
					@include webfont-icon($webfont-icon--i-table-arrow, after);

					&:after {
						color: $color-jazzberry-jam;
						position: absolute;
						top: 50%;
						transform: translateY(-35%);
						right: 11px;
						font-size: 14px;
					}
				}

				&--up {
					@include webfont-icon($webfont-icon--i-table-arrow, after);

					&:after {
						position: absolute;
						top: 50%;
						transform: translateY(-35%);
						right: 11px;
						font-size: 14px;
						transform: rotate(180deg) translateY(50%);
						color: $color-deep-sky-blue;

					}
				}

			}
		}

		thead {
			//Ячейки в заголовках

			td,
			th {
				@include rem(line-height, 20px);
				color: $table--header-color;
				background-color: $color-deep-sky-blue;
				text-align: center;
				border: 0;
				border-right: 1px solid $color-white;
				border-bottom: 1px solid $color-white;
				font-weight: bold;
			}

			tr:first-child {
				th:first-child,
				td:first-child {
					text-align: left;
					// &:before {
					// 	display: none;
					// }
				}
			}

		}

		tbody {
			color: $color-manatee;
			tr {
				// border-bottom: 1px solid $color-hit-gray;
				&:hover,
				&.is-hover {
					background-color: $table-row-bg-hover;

					.b-cell--strong {
						background-color: $table-row-bg-strong-hover;
						border-bottom: 1px solid $color-dark-cerulean;
					}
				}

				td {
					border-bottom: 1px solid $color-hit-gray;

					.browser-ie & {
						height: 100%;
					}
				}

				td:first-child,
				td:last-child {
						&:before{
							display: none;
						}

				}
			}
		}

		//Обычные ячейки (не в заголовках)
		td,
		th {
			position: relative;
			text-align: right;
			border-bottom-style: solid;
			@include rem(border-bottom-width, 1px);

			@include rem(line-height, 20px);
			transition: background-color 0.2s linear;
			background-clip: padding-box;
			text-align: center;
		}
	}

	//Выбранная строка(по клику)
	.b-selected-row {
		td {
			background-color: $table--selected-row-bg !important;
		}

		.b-cell--strong {
			background-color: $table--selected-row-bg-strong !important;
		}
	}

	.b-fixed-head {
		overflow: hidden;
		z-index: 10;
		visibility: hidden;
		position: fixed;
		top: 0;
		left: -99999px;
		margin-top: 134px;
		background-color: $table--fixed-head-bg;
	}

}

// Классы масштабирования
@for $i from 1 through 9 {
	.b-table--scale-#{$i},
	.b-table--auto-scale-#{$i} {
		table {
			td,
			th {
				font-size: 1em - $i / 10 !important;
				padding-left: 1em - $i / 10 !important;
				padding-right: 1em - $i / 10 !important;
			}
		}
	}
}

// Кнопка, скрывающая/раскрывающая таблицу
.b-table__toggle-visible-rows-trigger {
	background: $color-ironside-gray;
	display: inline-block;
	color: $color-white;
	@include rem(padding, 3px 10px);
	text-decoration: none;
}

// Стили для таблицы с горизонтальным скроллом
.b-table {
	&__content {
		overflow: auto;
	}

	&__content-wrapper {
		position: relative;
	}
}

.b-table__content,
.b-table {
	&::-webkit-scrollbar {
		height: 14px;
	}

	&::-webkit-scrollbar-track {
		background: $color-white;
	}

	&::-webkit-scrollbar-thumb {
		background: $color-deep-sky-blue;
	}

	&::-webkit-scrollbar-thumb:window-inactive {
		background-color: $color-deep-sky-blue;
	}

	//Firefox
	.browser-firefox & {
		scrollbar-color: $color-deep-sky-blue $color-white;
		scrollbar-width: thin;
	}
}

.b-fixed-scrollbar {
	display: none;
	overflow-x: scroll;
	position: fixed;
	width: 100%;
	margin-top: 0;
	z-index: 3;

	div {
		background: rgba(255, 255, 255, 0.01);
	}
}

.b-table--fixed-scrollbar {
	position: relative;
	overflow: hidden;
	// @extend .b-table--scale-1;

	table {
		// margin-top: -2px;
		// margin-bottom: 2px;

		.browser-safari & {
			margin-bottom: 0;
		}
	}

	.b-scroll-arrow {
		position: absolute;
		height: calc(100% - 14px);
		width: 30px;
		top: 0;
		margin-top: 0;
		z-index: 1;
		transition: opacity 0.3s ease, transform 0.3s ease;
		cursor: pointer;
		opacity: 1;
		font-size: 16px;

		&:not(.disabled):hover {
			opacity: 0.7;
		}

		&.disabled {
			opacity: 0.4;
		}

		&.loaded {
			transform: none;
		}

		#colorbox & {
			height: calc(100% - 1.125em);
		}
	}

	.b-scroll-arrow__icon {
		position: absolute;
		transform: translateY(-50%);
		color: $color-white;
	}

	.b-scroll-arrow--left {
		background: linear-gradient(90deg, rgba(97, 110, 121, 1), rgba(255, 255, 255, 0));
		left: 0;
		transform: translateX(-100%);

		&:not(.disabled):active {
			background: linear-gradient(90deg, rgba(253, 201, 10, 1), rgba(255, 255, 255, 0));
		}

		.b-scroll-arrow__icon {
			@include webfont-icon($webfont-icon--prev);
			margin-left: 5px;
		}
	}

	.b-scroll-arrow--right {
		background: linear-gradient(90deg, rgba(255, 255, 255, 0), rgba(97, 110, 121, 1));
		right: 0;
		transform: translateX(100%);

		&:not(.disabled):active {
			background: linear-gradient(90deg, rgba(255, 255, 255, 0), rgba(253, 201, 10, 1));
		}

		.b-scroll-arrow__icon {
			@include webfont-icon($webfont-icon--next);
			margin-left: 12px;
		}
	}

	// Промежуточная версия скроллбара без старого функционала
	.b-scroll-arrow {
		display: none;
	}

	.b-fixed-scrollbar {
		display: none !important;
	}

	&.b-table {
		overflow: hidden;
	}
}

@if ($responsive) {
	@media (min-width: $breakpoint-sm + 1px) {
		.b-table--no-xls .button--excel {
			display: none;
		}
	}

	@include breakpoint(sm) {
		.b-table {
			overflow-x: scroll;

			.b-fixed-head {
				display: none;
			}
		}

		.figure-buttons .button--excel,
		.figure-buttons .button--zoom,
		.b-table__toggle-visible-rows-trigger {
			display: none;
		}
	}
}

#cboxLoadedContent .b-table .figure-buttons {
	top: auto!important;
	bottom: 40px!important;
	left: 20px!important;
	right: auto!important;
}
