.figure-buttons {
	position: relative;

	margin-top: 10px;

	.button + .button {
		margin-left: 12px;
	}

	.button {
		padding: 0;
		text-decoration: none;
		@include font(300, 'VTBGroup')
	}
}

@if ($responsive) {
	@media (min-width: $breakpoint-sm + 1px) {
		.button--excel + .button {
			margin-left: 0;
		}
	}

	@include breakpoint(xs) {
		.figure-buttons {
			margin-top: 10px;
		}

		.figure-buttons .button {
			text-align: left;
			padding: 15px 30px;
			background-color: $color-deep-sky-blue;
			position: relative;
			width: 100%;
			text-decoration: none;
			color: $color-white;
			@include font(400, 'VTBGroup');
			font-size: 18px;
			line-height: 24px;
			text-transform: uppercase;
			border-radius: 41px;

			&:before {

				position: absolute;

				right: 30px;
				top: 50%;
				transform: translateY(-50%);
				font-size: 27px;
				margin-right: 0;
			}

			& + .button {
				margin-top: 10px;
				margin-left: 0;
			}

			&--excel {
				@include webfont-icon($webfont-icon--i-xls-mobile);
			}

			&--image {
				display: inline-flex;
				@include webfont-icon($webfont-icon--i-download-img-mobile);

				&:hover,
				&:focus {
					background-color: #00AEEF;
					color: #ffffff;
				}
			}

			&:active {
				color: $color-white;
				background-color: $color-dark-cerulean;

				&::before {
					color: $color-white;
				}
			}
		}
	}
}

