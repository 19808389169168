.inset {
	position: relative;
	border-left: none;
	border-top: 1px solid $color-deep-sky-blue;
	border-bottom: 1px solid $color-deep-sky-blue;
	@include rem(padding, 20px 3px 20px 3px);
	margin-bottom: 25px;

	figcaption,
	p {
		color: $color-dark-cerulean;
		@include font(400, 'VTBGroup');
		@include font-size (16px);
		line-height: 21px;
	}

	p:last-child {
		margin-bottom: 0;
	}

	.report-type--csr & {
		h2, h3 {
			margin-top: 0;
		}

		@include breakpoint(xs) {
			p {
				font-size: 14px;
			}
		}
	}
}

.inset--main {
	border: none;
	@include font-size(20px);
	@include rem(line-height, 26px);
	color: $color-dark-cerulean;
	@include rem(padding, 35px 35px 21px);

	figcaption {
		@include font-size(20px);
		@include rem(line-height, 26px);
		color: $color-dark-cerulean;
	}

	&.inset--white-smoke {
		background: $color-white-smoke;
	}

	&.inset--image {
		background-image: url(/images/icons/new-round.png);
		background-position: 100% 100%;
		background-size: 120px;
		background-repeat: no-repeat;
	}

	&.inset--dark {
		background: #0E4E96;

		p {
			color: $color-white;
		}

		a {
			color: white;

			&:visited {
				color: white;
			}

			&:hover,
			&:visited:hover,
			&:active,
			&:visited:active {
				color: #28AAE1;
			}

			&.link--external {
				&:before {
					color: white;
				}

				&:visited {
					color: white;

					&:before {
						color: white;
					}
				}

				&:hover,
				&:visited:hover {
					color: #28AAE1;

					&:before {
						color: #28AAE1;
					}
				}

				&:active,
				&:visited:active {
					color: #28AAE1;

					&:before {
						color: #28AAE1;
					}
				}
			}
		}
	}

	&.inset--anakiwa {
		background: $color-anakiwa;

		ul > li:before {
			background-color: white;
		}
	}

	.report-type--csr & {
		@include font-size(16px);

		h2, h3 {
			margin-top: 0;
		}

		&.inset--white-smoke {
			background: $color-lavender;

			&:after {
				content: '';
				display: block;
				width: 100%;
				position: absolute;
				top: 0;
				left: 0;
				border: 4px solid $color-prussian-blue;
			}
		}

		@include breakpoint(xs) {
			p {
				font-size: 14px;
			}
		}
	}

}

.inset--with-title {
	@include rem(padding, 25px 25px 25px);
	border: 1px solid $color-davys-grey;
	color: $color-davys-grey;

	figcaption {
		font-size: 18px;
		font-weight: bold;
		color: $color-davys-grey;
		position: absolute;
		top: 0;
		@include rem(left, 15px);
		transform: translateY(-50%);
		background: #fff;
		@include rem(padding, 0 10px);
	}

	& > *:last-child {
		margin-bottom: 0;
	}

	.report-type--csr & {
		h2, h3 {
			margin-top: 0;
		}

		@include breakpoint(xs) {
			p {
				font-size: 14px;
			}
		}
	}

}

.inset--link {
	display: inline-block;
	border-top: 1px solid $color-summer-sky;
	margin-bottom: 50px;
	padding: 0;
	padding-top: 6px;
	border-bottom: none;

	p {
		@include font(400, 'VTBGroup');
		@include font-size(16px);
		color: $color-bright-grey;
		line-height: 18px;
		font-style: normal;
	}

	.report-type--csr & {
		h2, h3 {
			margin-top: 0;
		}

		@include breakpoint(xs) {
			p {
				font-size: 14px;
			}
		}
	}
}

.inset-image {
	position: relative;

	&__text {
		position: absolute;
		padding: 7px 7px;
		background-color: $color-mountain-mist;
		opacity: 0.7;
		color: $color-black;
		top: 0;
	}
}

@include breakpoint(md) {
	.aside {
		position: static!important;
		width: 100%!important;
		padding-left: 0!important;
		padding-right: 0!important;
		margin-bottom: 20px;
	}

	.inset {
		margin-bottom: 34px;
	}
}

.custom_inset {
	min-height: 180px !important;
	@media (max-width: 1500px) {
		min-height: 240px !important;
	}
	@media (max-width: 1250px) {
		min-height: 290px !important;
	}
	@media (max-width: 1050px) {
		min-height: 260px !important;
	}
	@media (max-width: 825px) {
		min-height: 1px !important;
	}
}

.icons-row {
	display: none;
	flex-direction: row;
	flex-wrap: wrap;
	margin-bottom: 20px;

	&__item {
		margin: 0 5px 5px 0;
		width: 100px;
		height: 100px;
	}

	@include breakpoint(md) {
		display: flex;
	}

	@include breakpoint(sm) {
		&__item {
			width: 70px;
			height: 70px;
		}
	}
}

.aside--flex {
	@include breakpoint(md) {
		display: flex;

		.b-indicator {
			margin-right: 10px;

			.b-indicator__icon {
				margin-bottom: 10px;
			}
		}
	}
	@include breakpoint(xs) {
		display: block;

		.b-indicator {
			margin-right: 0;
		}
	}
}

.inset-equity {
	margin-bottom: 30px;
	min-width: 288px;

	.top-blue {
		background-color: $color-prussian-blue;
		padding-top: 20px;
		padding-bottom: 20px;
		color: white;
		text-align: center;
		position: relative;

		figcaption {
			text-transform: uppercase;
			font-size: 21px;
			line-height: 24px;
			margin-bottom: 20px;
		}

		.number {
			color: $color-deep-sky-blue;
			font-size: 26px;
			line-height: 24px;
		}

		.unit {
			font-size: 16px;
			line-height: 24px;
			margin-bottom: 15px;
		}

		.blue-line {
			position: absolute;
			bottom: -10px;

			span {
				background-color: $color-deep-sky-blue;
				color: $color-prussian-blue;
				text-align: left;
				width: fit-content;
				font-weight: bold;
				font-size: 16px;
				line-height: 24px;
				padding-left: 15px;
				padding-right: 25px;

			}

			&:after {
				content: '';
				width: 0;
				height: 0;
				bottom: 2px;
				right: -10px;
				position: absolute;
				border-right: 10px solid transparent;
				border-top: 20px solid $color-deep-sky-blue;
			}
		}
	}

	.bottom-grey {
		background-color: $color-lavender;
		padding: 20px 10px;
		min-height: 100px;
	}
}

.inset-alice-blue {
	background: $color-alice-blue;
	color: #00377B;
	font-weight: 600;
	padding: 20px 60px;
	display: block;

	figcaption {
		font-size: 18px;
	}
}

.inset-solitude {
	margin-bottom: 20px;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	height: 100%;

	figcaption {
		color: $color-prussian-blue;
		font-weight: 600;
		font-size: 21px;
		line-height: 1;
		margin-bottom: 15px;
	}

	&__inner {
		background-color: #ECF2FA;
		padding: 15px 20px;
		height: 80%;

		.image-row {
			display: flex;
			margin-bottom: 10px;

			.image {
				width: 100px;
				align-self: center;
			}

			.content {
				width: 100%;

				.content-row {
					display: flex;

					.indicator {
						width: 150px;
					}

					.text {
						width: 100%;
					}
				}

				.heading {
					color: $color-prussian-blue;
					font-weight: 600;
					font-size: 20px;
					line-height: 1;
				}

				.number {
					color: $color-prussian-blue;
					display: block;
					font-weight: bold;
					font-size: 60px;
					line-height: 60px;

					&--small {
						font-size: 50px;
						line-height: 44px;
					}
				}

				.unit {
					color: $color-prussian-blue;
					font-weight: bold;
					font-size: 24px;
					line-height: 1;
					display: block;

					&--small {
						font-size: 20px;
					}
				}
			}
		}

		p {
			color: $color-prussian-blue;
			font-weight: 600;
			font-size: 16px;
			line-height: 18px;
		}
	}

	&.inset-today {
		figcaption {
			min-height: 42px;

			@media (max-width: 1343px) {
				min-height: 63px;
			}

			@media (min-width: 864px) and (max-width: 1280px) {
				min-height: 42px;
			}

			@include breakpoint(sm) {
				min-height: auto;
			}
		}
	}
}

.inset--photo-background {
	background: no-repeat url(/images/content/forum-back.jpg);
	background-position: right;
	width: 100%;

	.inner {
		max-width: 50%;
		background-color: #fff;
		color: #00377B;
		position: relative;
		padding: 30px 20px 30px 30px;

		a {
			color: #00377B;

			&:visited {
				color: #00377B;
			}

			&:hover,
			&:visited:hover,
			&:active,
			&:visited:active {
				color: #28AAE1;
			}

			&.link--external {
				&:before {
					color: #00377B;
				}

				&:visited {
					color: #00377B;

					&:before {
						color: #00377B;
					}
				}

				&:hover,
				&:visited:hover {
					color: #28AAE1;

					&:before {
						color: #28AAE1;
					}
				}

				&:active,
				&:visited:active {
					color: #28AAE1;

					&:before {
						color: #28AAE1;
					}
				}
			}
		}

		ul > li {
			padding-left: 0.8em;
			position: relative;

			&:before {
				content: '';
				position: absolute;
				background-color: #28AAE1;
				top: calc(0.4em * 1.4);
				left: 0;
				width: 5px;
				height: 5px;
			}
		}

		.blue-line {
			width: 90%;
			height: 10px;
			background-color: #28AAE1;
			position: relative;
			margin-bottom: 30px;

			&:after {
				content: '';
				width: 0;
				height: 0;
				bottom: 0px;
				right: -6px;
				position: absolute;
				border-right: 6px solid transparent;
				border-top: 10px solid $color-deep-sky-blue;
			}
		}
	}

	@media (max-width: 1400px) {
		.inner {
			max-width: 60%;
		}
	}

	@include breakpoint(sm) {
		background: none;

		.inner {
			max-width: 100%;
		}
	}
}

.inset-skew {
	max-width: 900px;
	padding: 20px 40px;
	background-color: #00377B;
	transform: skew(-25deg);
	margin-left: 40px;
	margin-bottom: 20px;
	margin-right: 10px;
	position: relative;

	a {
		color: white;

		&:visited {
			color: white;
		}

		&:hover,
		&:visited:hover,
		&:active,
		&:visited:active {
			color: #28AAE1;
		}

		&.link--external {
			&:before {
				color: white;
			}

			&:visited {
				color: white;

				&:before {
					color: white;
				}
			}

			&:hover,
			&:visited:hover {
				color: #28AAE1;

				&:before {
					color: #28AAE1;
				}
			}

			&:active,
			&:visited:active {
				color: #28AAE1;

				&:before {
					color: #28AAE1;
				}
			}
		}
	}

	.icon-skew,
	p {
		color: white;
		transform: skew(25deg);
	}

	figcaption {
		background-color: #28AAE1;
		padding-left: 10px;
		padding-right: 15px;
		width: fit-content;
		min-width: 135px; //for safari 13
		position: absolute;
		top: 0px;
		left: 10px;
		margin-bottom: 15px;

		div {
			color: #00377B;
			font-weight: 600;
			transform: skew(25deg);
		}
	}

	div.row {
		transform: skew(25deg);
	}

	&-flex {
		display: flex;
	}

	@include breakpoint(sm) {
		padding: 20px;
		transform: skew(0deg);
		margin: 20px 0;

		.b-indicator {
			transform: skew(-25deg);
		}

		.icon-skew,
		p {
			transform: skew(0deg);
		}

		div.row {
			transform: skew(0deg);
		}

		figcaption {
			div {
				transform: skew(0deg);
			}
		}

		&-flex {
			display: inline-block;
		}
	}

	@include breakpoint(xs) {
		&-flex {
			padding: 10px 10px;
			transform: skew(0deg);
			margin: 0px;

			.b-indicator {
				transform: skew(-25deg);
			}

			p {
				transform: skew(0deg);
			}
		}
	}
}

.inset-grey-skew {
	padding: 10px 40px;
	background-color: #EEEFF6;
	transform: skew(-25deg);
	margin-bottom: 20px;
	position: relative;

	p {
		color: $color-prussian-blue;
		transform: skew(25deg);
		margin-bottom: 0px;
	}
}

.inset-rights {
	position: relative;
	border-radius: 30px;
	background-color: #00AEEFBB;
	padding: 60px 40px 40px;
	height: 100%;
	margin-bottom: 30px;

	figcaption {
		background-color: white;
		padding: 5px 0px 10px;
		margin-bottom: 20px;
		border-radius: 0 0 20px 20px;
		position: absolute;
		top: 0;
		left: 50%;
		transform: translate(-50%);

		span {
			border: 1px solid $color-dark-cerulean;
			border-top: none;
			border-radius: 0 0 15px 15px;
			margin: 5px;
			color: #00AEEF;
			padding: 5px 20px;
			font-weight: bold;
		}
	}

	p {
		color: white;
		padding-left: 30px;
	}

	&-obligations {
		background-color: #00377BE9;

		figcaption {
			span {
				border: 1px solid #00AEEF;
				border-top: none;
			}
		}
	}
}

.inset-back {
	position: relative;

	.photo {
		width: 40%;
		height: 100%;
	}

	.inset-skew {
		transform: skew(-20deg);
		position: absolute;
		left: 35%;
		top: 0px;

		@media (max-width: 1760px) {
			top: 50px;
		}

		@media (max-width: 1280px) {
			top: 100px;
		}

		@media (max-width: 950px) {
			top: 50px;
		}
	}

	.inset-ellipse {
		position: absolute;
		bottom: 30%;
		left: 1px;
		width: 39%;
		padding: 15px 60px 15px 20px;

		&:after {
			border-left: 70px solid transparent;
		}

		@media (max-width: 1760px) {
			bottom: 120px;
			width: 72%;
		}
	}

	.row {
		position: absolute;
		right: 0px;
		top: 34%;
		width: 62%;

		@media (max-width: 1760px) {
			display: none;
		}
	}

	@include breakpoint(sm) {
		display: none;
	}
}

.inset-back-rev {
	display: none;

	@media (max-width: 1760px) {
		display: flex;
	}
}
