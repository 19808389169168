/* Links */

/**
 * Remove the gray background color from active links in IE 10.
 */



a {
	color: $link-default-color;
	background-color: transparent;
	transition: color 0.2s ease;
	@include font-size (16px);
	line-height: 21px;

	&:visited {
		color: $link-visited-color;
	}


	&:hover,
	&:visited:hover {
		color: $link-hover-color;
	}

	&:active,
	&:visited:active {
		color: $link-active-color;
	}

	&:active,
	&:hover {
		outline: 0;
	}

	&.btn {
		&:visited {
			color: $btn--color;
		}

		&:hover,
		&:visited:hover {
			color: $btn--color-hover;
		}

		&:active,
		&:visited:active {
			color: $btn--color-active;
		}
	}

	&.link--dark {
		color: #00377B;

		&:visited {
			color: #00377B;
		}

		&:hover,
		&:visited:hover {
			color: $link-hover-color;
		}

		&:active,
		&:visited:active {
			color: $link-active-color;
		}
	}

	&.link--white {
		color: #FFFFFF;

		&:before {
			color: #FFFFFF !important;
		}
	}

	&.link--external {
		text-decoration: none;
		&:before {
			transition: color 0.2s ease;
			color: $link-default-color;
		}

		&:visited {
			text-decoration: none;
			&:before {
				color: #00aeef;
			}
		}

		&:hover,
		&:visited:hover {
			text-decoration: none;
			&:before {
				color: $link-hover-color;
			}
		}

		&:active,
		&:visited:active {
			text-decoration: none;
			&:before {
				color: #00aeef;
			}
		}
	}

	&.link-no-ex {
		text-decoration: none;
		&:before {
			content: none;
		}
	}

	&.link--revert {
		color: $link-active-color;
		&:before {
			color: $link-active-color;
		}

		&:hover,
		&:visited:hover {
			color: $link-default-color;
			&:before {
				color: $link-default-color;
			}
		}

		&:active,
		&:visited:active {
			color: $link-hover-color;
			&:before {
				color: $link-hover-color;
			}
		}
	}

	@include breakpoint(xs) {
		@include font-size (13px);
	}
}

.no-visited {
	color: #00377B;
	&:before {
		color: #00377B !important;
	}

	&:active,
	&:visited:active {
		&:before {
			color: $link-default-color !important;
		}
	}

	&:hover {
		color: #00377B;
		&:before {
			color: $link-hover-color !important;
		}
	}

	&:visited {
		color: #00377B;
		&:before {
			color: #00377B !important;
		}
	}
}

[data-anchor-link] {
	cursor: pointer;
}

.link--external {
	@include webfont-icon($webfont-icon--i-external);

	&:before {
		display: inline;
		@include font-size(11px);
		margin-right: 0.2em;
	}
}

.link--phone {
	white-space: nowrap;
}

.b-link-icon {
	display: flex;
	//flex: 0 1 auto;
	align-items: center;

	&__icon {
		margin-right: 5px;
		flex-basis: 100%;
		max-width: 25px;
		min-width: 20px;
	}

	&__text {
		flex-shrink: 1;
	}
}

//иконка слева
.b-link-icon--left {
	.b-link-icon__icon {
		img {
			display: block;
		}
	}
}

//иконка справа
.b-link-icon--right {
	.b-link-icon__icon {
		margin-left: 5px;
		margin-right: auto;

		img {
			display: block;
		}
	}
}

//размеры иконок, при необходимости - добавлять модификаторы - xs, xm, xl

//по умолчанию без модификации
.b-link-icon--size-s {
}

//средний размер
.b-link-icon--size-m {
	.b-link-icon__icon {
		flex-basis: 100%;
		max-width: 30px;
	}
}

//большой размер
.b-link-icon--size-l {
	.b-link-icon__icon {
		flex-basis: 100%;
		max-width: 40px;
	}
}

.b-link-icon--size-free {
	.b-link-icon__icon {
		flex-basis: auto;
		max-width: initial;
	}
}

.link-in-svg--tel {
	font-size: 13px;
	text-decoration: underline;

	&:visited {
		fill: $color-white;
	}
}

.link-in-svg--inf-discl {
	font-size: 9px;
}
