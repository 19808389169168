$pagenav--link-width: 30px;
$pagenav--link-height: 30px;

$pagenav--icon-width: $pagenav--link-width / 1.5;
$pagenav--icon-height: $pagenav--link-width - 4;
$pagenav--icon-color: #28AAE1;
$pagenav--icon-color-hover: #28AAE1;
$pagenav--icon-color-active: $color-bright-grey;

$pagenav--btn-bg: transparent;
$pagenav--btn-bg-hover: transparent;
$pagenav--btn-bg-active: transparent;

$pagenav--title-width: 250px;
$pagenav--title-color: transparent;
$pagenav--title-color-hover: $color-dark-cerulean;
$pagenav--title-color-active: $color-bright-grey;
$pagenav--title-bg: transparent;
$pagenav--title-bg-active: transparent;

@if ($contrast) {
	$pagenav--icon-color: $color-celeste;
	$pagenav--icon-color-hover: $color-black;
	$pagenav--icon-color-active: $color-carrot-orange;

	$pagenav--btn-bg: $color-eallery;
	$pagenav--btn-bg-hover: $color-celeste;
	$pagenav--btn-bg-active: $color-dark-gray;

	$pagenav--title-color: $color-white;
	$pagenav--title-bg: $color-dark-gray;
	$pagenav--title-bg-active: $color-dark-gray;
}
