.b-indicator {
	display: block;
	max-width: 265px;
	margin-bottom: 30px;

	&__value {
		@include font(700, 'VTBGroup');
		display: inline-block;
		@include font-size(50px);
		line-height: 60px;
		color: $color-dark-cerulean;

		@media (max-width: 1400px) {
			@include font-size(44px);
		}
	}

	&__value--big {
		@include font-size(45px);
	}

	&__value--small {
		@include font-size(36px);
	}

	&__value--arrow {
		@include webfont-icon($webfont-icon--up);

		&:before {
			vertical-align: top;
			font-size: 0.5em;
		}
	}

	&__value--arrow-up {}

	&__value--arrow-down {
		&:before {
			transform: rotate(180deg);
		}
	}

	&__value--print {
		display: none !important;
	}

	&__unit {
		display: inline-block;
		@include font-size(27px);
		font-weight: 700;
		line-height: 1;
		color: $color-dark-cerulean;
	}

	&__unit--persent {
			@include font-size(40px);
	}

	&__unit--big {
		@include font-size(48px);
	}

	&__unit--small {
		@include font-size(16px);
		font-weight: 400;
	}

	&__text {
		// @include font(700, 'VTBGroup');
		@include font(400, 'VTBGroup');
		display: block;
		@include font-size(16px);
		line-height: 20px;
		line-height: 1.2;
		// color: $color-dark-cerulean;
		color: $color-bright-grey;
	}

	&__text--big {
		@include font-size(18px);
		font-weight: 700;
	}

	&__text--small {
		@include font-size(12px);
	}

	&__value,
	&__unit,
	&__text {
		& + & {
			@extend %collapse--top;
		}
	}

	&__value--color,
	&__unit--color {
		color: $color-deep-sky-blue;
	}

	&__text--color {
		color: $color-dark-cerulean;
	}

	&--with-bg {
		background-color: $color-dark-cerulean;
		@include rem(padding, 28px 19px 28px 19px);
		min-height: 172px;
		max-width: 273px;

		.b-indicator__value,
		.b-indicator__unit,
		.b-indicator__text {
			color: $color-white;

			&.color-deep-sky-blue {
				color: $color-deep-sky-blue;
			}
		}

		.b-indicator__value {
			margin-right: 10px;

			@if ($responsive) {
			    @include breakpoint(lg) {
			      @include font-size(48px);
			    }
			    @include breakpoint(md) {
			      @include font-size(60px);
			    }
			}
		}
	}

	.report-type--csr & {
		&--with-bg {
			@include rem(padding, 19px);
			min-height: auto;
			margin-bottom: 20px;
			.b-indicator__value {
				line-height: 50px;
			}
		}
	}

	&--in-tooltip {
		margin-bottom: 10px;

		.b-indicator__value {
			@include font-size(42px);
			line-height: 50px;

			@media (max-width: 1400px) {
				@include font-size(32px);
				line-height: 40px;
			}
		}

		.b-indicator__unit {
			@include font-size(22px);
		}

		.b-indicator__text {
			@include font-size(14px);
			line-height: 1.2;
		}
	}
}
