.graphic {
	margin-bottom: 50px;

	.report-type--csr & {
		margin-top: 40px;
	}

	&__img {
		@extend %collapse--top;
	}

	figcaption {
		@include font(600, 'VTBGroup');
		@include font-size(16px);
		line-height: 18px;
		color: $color-manatee;
		border-bottom: 1px solid $color-deep-sky-blue;
		padding-bottom: 10px;
		margin-bottom: $grid-gutter-width;

		text-transform: uppercase;

		.caption__units {
			font-weight: normal;
			text-transform: none;
		}

		@include breakpoint(xs) {
			@include font-size(14px);
			line-height: 16px;
			padding-bottom: 7px;
			margin-bottom: 19px;
		}
	}

	&--chart {

		@include breakpoint(xs) {
			max-width: none!important;
		}
	}
}


.graphic__tools {
	@extend %clearfix;
	@extend %collapse--top;
	position: relative;
	border-top: 1px solid;

	.button + .button {
		margin-right: 1px;
	}
}
