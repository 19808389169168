$header-bg: $color-white;
$body-bg: $color-white;
$footer-bg: $color-white;

@if ($contrast) {
	$header-bg: #656565;
	$body-bg: #656565;
	$footer-bg: #656565;
}

body {
	background-color: $body-bg;
	overflow-x: hidden;
}

.l-layout-wrapper {
	position: relative;
	display: flex;
	flex-direction: row;
	min-height: 100vh;
	width: $site-width;
	margin: 0 auto;

	@media (max-width: 1680px) {
		// overflow-x: hidden;

		.browser-ie & {
			overflow: visible;
		}
	}

	.l-layout-wrapper__menu {
		position: fixed;
		height: 100%;

		margin-top: 64px;
	    width: 100%; //ширина меню
    	max-width: 440px;
    	padding: 15px;
    	// z-index: 10;
    	background-color: #E1F4FD;
    	border-right: 1px solid #28AAE1;
	}

	.l-layout-wrapper__container {
		padding-left: 440px;

		// position: relative;
		display: flex;
		flex-direction: column;
		min-height: 100vh;
		// width: $site-width;
		margin: 0 auto;
		width: 100%;
		// max-width: 1480px;

		.body--main-page & {
			padding-left: 0;
		}
	}

	&__header {
		flex: none;
		// @include rem(padding-bottom, 10px);
		position: fixed;//relative;
		left: 0;
		top: 0;
		width: 100%;
		height: 78px;
		margin-bottom: 19px;
		z-index: 11;

		&:after {
			content: "";
			display: block;
			position: absolute;
			left: 50%;
			width: 100vw;
			margin-left: -50vw;
			bottom: -1px;
			height: 1px;
			background: #00377B;
		}

		&:before {
			content: '';
			position: absolute;
			width: 100vw;
			height: 100%;
			top: 0;
			left: 50%;
			transform: translateX(-50%);
			background-color: $header-bg;
			z-index: -1;
		}
	}

	&__footer {
		background-color: $footer-bg;
		flex: none;
		position: relative;

		&:before {
			// content: "";
			display: block;
			position: absolute;
			left: 50%;
			width: 100vw;
			margin-left: -50vw;
			top: 0;
			height: 2px;
			background: $color-deep-sky-blue;
		}
	}
}

.l-page {
	display: flex;
	flex-direction: row;
	flex-grow: 1;
	flex-shrink: 0;
	min-height: 1px;
	// padding-top: 157px;
	padding-top: 65px;

	&__sidebar {
		flex-grow: 0;
		flex-shrink: 0;
	}

	&__sidebar--left {
		flex-basis: $left-col-width;
		width: $left-col-width;
		min-width: $left-col-width;
	}

	&__sidebar--right {
		flex-basis: $right-col-width;
		width: $right-col-width;
		min-width: $right-col-width;
	}

	&__sidebar-section {
		@include rem(margin-bottom, 10px);
	}

	&__sidebar-section--adaptive-menu {
		display: none;
	}

	&__content {
		padding-bottom: 3em;
		padding-left: $grid-gutter-width;
		padding-right: $grid-gutter-width;
		flex-shrink: 1;
		flex-grow: 1;
		position: relative;
		width: 100%;
	}

	&__content--with-left {
		padding-left: 0;
		width: $left-col-width + $center-col-width;
	}

	&__content--with-right {
		padding-right: 0;
		width: $right-col-width + $center-col-width;
	}

	&__content--wide {
		padding-right: 0;
		padding-left: 0;
		width: 100%;
	}

	&__nav {
		display: flex;
		align-items: center;
		@include rem(margin-bottom, 27px);
	}

	&__nav-breadcrumbs {
		flex-grow: 1;
	}

	&__nav-controls {
		flex-grow: 0;
		flex-shrink: 0;
	}
}

.l-layout-wrapper__header,
.l-layout-wrapper__page,
.l-footer__inner {
	padding-left: $grid-gutter-width;
	padding-right: $grid-gutter-width;
}

.content-area {
	min-height: 1px;
}

.l-content--with-left {
	margin-left: -($left-col-width + 15px);
}

.l-content--with-right {
	margin-right: -($right-col-width + 15px);
}

.aside {
	position: absolute;
	margin-top: 0;
}

.aside--right {
// 	width: $right-col-width;
// 	right: -$right-col-width;
// 	padding-left: $grid-gutter-width;
    width: 288px;
    right: -288px;
    padding-left: 48px;

    .b-accordion & {
    	right: -398px;

    	@include breakpoint(lg) {
    		right: -358px;
    	}
    }
}

.aside--left {
	width: $left-col-width;
	left: -$left-col-width;
	padding-right: $grid-gutter-width;
}

.aside + .aside {
	margin-top: 150px;

	@include breakpoint(sm) {
		margin-top: 0px;
	}
}

@if ($responsive) {
	.l-layout-wrapper {
		width: 100%;
		max-width: $site-width;
		min-width: $site-min-width;
	}

	@media (max-width: 1480px) {
		.l-layout-wrapper {
			.l-layout-wrapper__menu {
		    	max-width: 300px;
			}

			.l-layout-wrapper__container {
				padding-left: 300px;
			}
		}
	}

	@include breakpoint(md) {
		// .l-layout-wrapper {
		// 	.l-layout-wrapper__menu {
		//     	max-width: 300px;
		// 	}
		// }

		.l-layout-wrapper__header {
			padding-left: $grid-gutter-width;
			padding-right: $grid-gutter-width;
		}

		.l-layout-wrapper__page {
			padding-left: $grid-gutter-width;
			padding-right: $grid-gutter-width;
		}

		// .l-layout-wrapper__footer {
		// 	padding-left: $grid-gutter-width;
		// 	padding-right: $grid-gutter-width;
		// }

		.l-page__content {
			width: auto;
			//overflow: hidden;
		}
	}

	@media (max-width: 1280px) {
		.l-layout-wrapper {
			.l-layout-wrapper__menu {
				display: none;
			}

			.l-layout-wrapper__container {
				padding-left: 0px;
			}
		}
	}

	@include breakpoint(sm) {

		.l-page__content {
			padding-left: 0;
			padding-right: 0;
		}

		.l-page__sidebar-section--menu {
			display: none;
		}

		.l-page__sidebar-section--adaptive-menu {
			display: block;
		}

		.l-page__sidebar.l-page__sidebar--right {
			display: none;
		}
	}
}
