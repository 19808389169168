.b-logo {
	display: block;
	width: 93px;
	height: 33px;

	// @media (max-width: 798px) {
	// 	width: 67px;
	// 	height: 24px;
	// }

	&__image {
		width: 100%;
		height: 100%;
	}

	&__text {
		display: none;
	}
}

.logo-svg {
	width: 100%;
	height: 100%;
}

@include breakpoint(sm) {
	.b-logo {
		height: 25px;
		max-height: 65px;
		width: 67px;
		// height: 24px;

		@include font-size(10px);
		line-height: 12px;
		text-decoration: none;
		text-transform: uppercase;
		line-height: 1;

		&__image {
			width: 100%;
			height: 100%;
		}

		&__text {
			display: block;
			min-width: 180px;
			padding-top: 4px;
		}
	}
}
