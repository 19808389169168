// figma
@import './../../styles/figma/tokens-colors.scss';

// Цвета
$color-black: #000000;
$color-onyx: #111111;
$color-dark-jungle: #222222;
$color-mine-shaft: #333333;
$color-tundora: #444444;
$color-davys-grey: #555555;
$color-ironside-gray: #666666;
$color-tapa-gray: #777777;
$color-taupe-gray: #888888;
$color-mountain-mist: #999999;
$color-dark-gray: #aaaaaa;
$color-gray: #bbbbbb;
$color-celeste: #cccccc;
$color-light-gray: #dddddd;
$color-eallery: #eeeeee;
$color-white: #ffffff;
$color-navy-blue: #0079c1;
$color-prussian-blue: #00426a;
$color-error: #e24831;
$color-carrot-orange: #f39318;

.color-navy-blue {
	color: $color-navy-blue;
}

$color-1: #87ceeb;

$color-deep-sky-blue: #00AEEF;
$color-dark-cerulean: #00377B;
$color-summer-sky: #3EB5F1;
$color-bright-grey: #58595B;
$color-dim-gray: #666666;
$color-manatee: #87888C;
$color-cerulean: #0078B8;
$color-night-rider: #333333;
$color-alice-blue: #E1F4FD;
$color-alice-blue-lighter: #E6F6FF;
$color-hit-gray: #ADAFAF;
$color-hit-grey: #ADAFAF;
$color-pacific-blue: #008ACC;
$color-jazzberry-jam: #BE006E;
$color-air-force-blue: #597da9; // #00377B 0.65
$color-darkc-cerulean-2: #0C317F;

$color-white-smoke: #F6F6F6;
$color-lavender: #EEEFF6;
$color-anakiwa: #90D3F4;
$color-prussian-blue: #00377B;
$color-summer-sky: #28AAE1;
$color-solitude: #ECF2FA;
$color-alice-blue: #E1F4FD;

.color-dark-cerulean {
	color: $color-dark-cerulean;
}
.color-deep-sky-blue {
	color: $color-deep-sky-blue;
}
.color-prussian-blue {
	color: $color-prussian-blue;
}

.color-summer-sky {
	color: $color-summer-sky;
}