.b-noty--old-browser {
	.b-noty__body {
		text-align: left;
		@include rem(padding, 30px);
		background-color: rgb(223, 223, 223);
	}

	.b-noty__text a {
		color: $project--main-color;

		&:hover {
			text-decoration: none;
		}
	}
}
