// Классы для вертикального поворота текста в ячейке таблицы
.table-rotate-row {
	vertical-align: bottom;
	text-align: right;
}

.table-rotate-content {
	writing-mode: tb-rl;
	transform: rotate(180deg);
	text-align: left;
}

.flex-wrapper {

	@include breakpoint(md) {
		display: flex;
		flex-wrap: wrap;

		.row {
			flex: 0 0 100%;
			order: 0;
		}

		.aside {
			flex: 0 0 50%;

			&--1 {
				order: 1;
			}

			&--2 {
				order: 2;
			}
		}
	}

	@include breakpoint(xs) {
		.aside {
			flex: 0 0 100%;
		}
	}
}

.flex-wrapper-aside {

	@include breakpoint(md) {
		display: flex;
		justify-content: space-between;

		strong {
			flex: 0 0 50%;
		}
	}

	@include breakpoint(xs) {
		flex-direction: column;

		strong {
			flex: 0 0 100%;
		}
	}
}

.person_name {
	@include font(600, 'VTBGroup');
	// font-size: 18px;
	font-size: 16px;
	line-height: 20px;
	// color: $color-deep-sky-blue;
	color: $color-prussian-blue;
	margin: 10px 0 10px;
	text-align: center;

	&-main {
		// font-size: 20px;
		font-size: 18px;
		text-align: left;
		margin-top: 0;

		@include breakpoint(sm) {
			margin-top: 10px;
		}
	}

	@include breakpoint(sm) {
		@include font-size(16px);
		line-height: 22px;
	}

	@include breakpoint(xs) {
		@include font-size(14px);
		line-height: 20px;
	}
}

.person_post {
	// font-style: italic;
	text-align: center;
	margin-bottom: 15px;

	&-main {
		text-align: left;
	}
}


.hide-inset {
	@media (max-width: 1650px) and (min-width: 1281px) {
		display: none;
	}
}

.hide-inset-desktop {
	@media (max-width: 1900px) {
		display: none;
	}
}

.hide-inset-sm {
	@media (max-width: 761px) and (min-width: 500px) {
		display: none !important;
	}
}