$copyright_base--color: $color-white;
$copyright_base--link-color: rgba($color-white, 0.8);
$copyright_base--link-color-hover: $color-white;
$copyright_base--link-color-active: $color-white;

@if ($contrast) {
	$copyright_base--color: $color-white;
	$copyright_base--link-color: $color-white;
	$copyright_base--link-color-hover: $color-white;
}

.b-copyright {
	color: $copyright_base--color;

	&__name--link {
		color: $copyright_base--link-color;
		text-decoration: none;
		@include font-size(14px);
		@include rem(line-height, 0);

		&:visited {
			color: $copyright_base--link-color;
		}

		&:hover,
		&:visited:hover {
			text-decoration: none;
			color: $copyright_base--link-color-hover;
		}

		&:active,
		&:visited:active {
			color: $copyright_base--link-color-active;
		}
	}

	@include breakpoint(xs) {
		@include font-size(12px);
	}
}
