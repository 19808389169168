.breadcrumbs--short {
	position: relative;
	overflow: hidden;
	text-overflow: ellipsis;
	display: inline-block;
	width: 100%;
	padding-right: 10px;
	padding-left: 2px;
	margin-left: -2px;

	&:after {
		content: '';
		position: absolute;
		top: 0;
		right: 0;
		height: 100%;
		width: 10px;
		background-image: linear-gradient(to right, rgba(255, 255, 255, 0), rgba(255,255,255,1));
	}

	ul.breadcrumbs__list {
		display: flex;
		flex-shrink: 0;
		flex-grow: 1;
		margin-top: 0;
	}

	.breadcrumbs__item {
		display: flex;
		align-items: center;
		white-space: nowrap;
		color: $color-dark-cerulean;
		@include font(300);
		font-size: 12px;
		line-height: 17px;

		.browser-ie & {
			flex-shrink: 0;
		}
	}

	.breadcrumbs__item + .breadcrumbs__item {
		@include webfont-icon($webfont-icon--arrow-right, after);

		&:after {
			font-size: 5px;
			background: none;
			width: auto;
			height: auto;
			top: 50%;
			transform: translateY(-50%);
		}
	}

	.breadcrumbs__link {
		@include font(300);
		font-size: 12px;
		line-height: 17px;
		color: $color-manatee;

		&--home {
			font-size: 17px;
			line-height: 0;
			@include webfont-icon($webfont-icon--i-home);
			color: $color-dark-cerulean;

			&:hover {
				color: $color-deep-sky-blue;
			}
		}
	}
}

.breadcrumbs--short .tooltip {
	border-bottom: none;
}

.breadcrumbs--short .breadcrumbs__item:nth-child(1):last-child {
	max-width: 95%;
}

.breadcrumbs--short .breadcrumbs__item:nth-child(2):last-child {
	max-width: 90%;
}

.breadcrumbs--short .breadcrumbs__item:nth-child(3):last-child {
	max-width: 85%;
}

.breadcrumbs--short .breadcrumbs__item:nth-child(4):last-child {
	max-width: 80%;
}

.breadcrumbs--short .breadcrumbs__item:nth-child(5):last-child {
	max-width: 75%;
}

.breadcrumbs--short .breadcrumbs__item .tooltip__text {
	white-space: normal;
}

.breadcrumbs--short .breadcrumbs__item-inner:not(.breadcrumbs__item-inner--home) {
	width: 100%;
	margin-top: 0;
	overflow: hidden;
	text-overflow: ellipsis;
}

.breadcrumbs--short .breadcrumbs__short-dots {
	display: none;
}

.breadcrumbs--short .breadcrumbs__short-dots:before {
	content: '...';
	display: inline-block;
	width: auto;
	height: auto;
	background: 0 0;
	position: static;
}

.breadcrumbs--short .breadcrumbs__item.is-short .breadcrumbs__item-inner {
	// display: none;
	visibility: hidden;
	width: auto;
	position: absolute;
}

.breadcrumbs--short .breadcrumbs__item.is-short .breadcrumbs__short-dots,.copyright__name {
	display: inline-block;
}
