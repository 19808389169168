$extendable-input-width: 462px;
$extendable-input-border-color: $color-deep-sky-blue;

.b-search {
	&.b-search--extendable {
		position: relative;
		width: auto;

		.b-search__wrapper-input {
			@extend %d-none;
			@include rem(width, $extendable-input-width);
			height: 27px;
			z-index: 10;
			position: absolute;
			top: -3px;
			right: -9px;
		}

		.b-search__label {
			position: absolute;
			color: $color-hit-gray;
			font-size: 14px;
			line-height: 27px;
			letter-spacing: -0.01em;
			margin: auto;
			top: 0;
			bottom: 0;
			@include rem(height, 27px);
			@include rem(left, 13px);
			cursor: text;
		}

		.b-search__input {
			width: 100%;
			height: 100%;
			@include rem(padding-right, 50px);
			margin-top: 0;
			border: 1px solid $extendable-input-border-color;
			border-radius: 15px;
			padding-left: 13px;
			font-size: 14px;
			letter-spacing: -0.01em;
			color: $color-bright-grey;
		}

		.b-search__button {
			display: block;
			padding: 0;
			background-color: transparent;
			color: $color-deep-sky-blue;
			@include webfont-icon($webfont-icon--search_2);
			@extend %webfont-button-animation; /* /src/styles/styles/utilities.scss */

			&:before {
				display: block;
				@include font-size(22px);
			}

			&:hover {
				color: $color-dark-cerulean;
			}

			&:active,
			&.is-expand {
				color: transparent;
			}
		}

		.b-search__result {
			@include rem(width, $extendable-input-width);
		}

		.b-search__button-close:before {
			font-size: 18px;
			color: $color-dark-cerulean;
		}

		.search__additional-buttons {
			right: 6px;
		}

		.b-search__button-speech {
			display: none;
		}
	}
}
