$figma--pseudo-links--link_normal--bg: transparent;
$figma--pseudo-links--link_normal--border: transparent;
$figma--pseudo-links--link_normal--color: rgba(36, 31, 33, 1);
$figma--pseudo-links--link_hover--bg: transparent;
$figma--pseudo-links--link_hover--border: transparent;
$figma--pseudo-links--link_hover--color: rgba(0, 173, 240, 1);
$figma--pseudo-links--link_active--bg: transparent;
$figma--pseudo-links--link_active--border: transparent;
$figma--pseudo-links--link_active--color: rgba(135, 135, 140, 1);
$figma--pseudo-links--link_visited--bg: transparent;
$figma--pseudo-links--link_visited--border: transparent;
$figma--pseudo-links--link_visited--color: rgba(89, 125, 168, 1);

$figma--links--link_normal--bg: transparent;
$figma--links--link_normal--border: transparent;
$figma--links--link_normal--color: rgba(0, 173, 240, 1);
$figma--links--link_hover--bg: transparent;
$figma--links--link_hover--border: transparent;
$figma--links--link_hover--color: rgba(135, 135, 140, 1);
$figma--links--link_active--bg: transparent;
$figma--links--link_active--border: transparent;
$figma--links--link_active--color: rgba(0, 56, 122, 1);
$figma--links--link_visited--bg: transparent;
$figma--links--link_visited--border: transparent;
$figma--links--link_visited--color: rgba(89, 125, 168, 1);

$figma--icons--icon_normal--bg: transparent;
$figma--icons--icon_normal--border: transparent;
$figma--icons--icon_normal--color: rgba(135, 135, 140, 1);
$figma--icons--icon_hover--bg: transparent;
$figma--icons--icon_hover--border: transparent;
$figma--icons--icon_hover--color: rgba(0, 173, 240, 1);
$figma--icons--icon_active--bg: transparent;
$figma--icons--icon_active--border: transparent;
$figma--icons--icon_active--color: rgba(0, 56, 122, 1);

$figma--buttons--button_normal--bg: rgba(135, 135, 140, 1);
$figma--buttons--button_normal--border: rgba(135, 135, 140, 1);
$figma--buttons--button_normal--color: rgba(255, 255, 255, 1);
$figma--buttons--button_hover--bg: rgba(0, 173, 240, 1);
$figma--buttons--button_hover--border: rgba(0, 173, 240, 1);
$figma--buttons--button_hover--color: rgba(255, 255, 255, 1);
$figma--buttons--button_active--bg: rgba(0, 56, 122, 1);
$figma--buttons--button_active--border: rgba(0, 56, 122, 1);
$figma--buttons--button_active--color: rgba(255, 255, 255, 1);
$figma--buttons--button_visited--bg: rgba(173, 176, 176, 1);
$figma--buttons--button_visited--border: rgba(173, 176, 176, 1);
$figma--buttons--button_visited--color: rgba(255, 255, 255, 1);
$figma--buttons--button_disabled--bg: rgba(255, 255, 255, 1);
$figma--buttons--button_disabled--border: rgba(173, 176, 176, 1);
$figma--buttons--button_disabled--color: rgba(173, 176, 176, 1);

$figma--checkboxes--checkbox_normal--bg: rgba(255, 255, 255, 1);
$figma--checkboxes--checkbox_normal--border: rgba(135, 135, 140, 1);
$figma--checkboxes--checkbox_normal--color: rgba(255, 255, 255, 1);
$figma--checkboxes--checkbox_hover--bg: rgba(255, 255, 255, 1);
$figma--checkboxes--checkbox_hover--border: rgba(0, 173, 240, 1);
$figma--checkboxes--checkbox_hover--color: rgba(255, 255, 255, 1);
$figma--checkboxes--checkbox_active--bg: rgba(255, 255, 255, 1);
$figma--checkboxes--checkbox_active--border: rgba(0, 56, 122, 1);
$figma--checkboxes--checkbox_active--color: rgba(0, 56, 122, 1);
$figma--checkboxes--checkbox_party--bg: rgba(230, 230, 230, 1);
$figma--checkboxes--checkbox_party--border: rgba(140, 156, 161, 1);
$figma--checkboxes--checkbox_party--color: rgba(140, 156, 161, 1);

$figma--accordion--accordion_normal--bg: transparent;
$figma--accordion--accordion_normal--border: rgba(0, 173, 240, 1);
$figma--accordion--accordion_normal--color: rgba(135, 135, 140, 1);
$figma--accordion--accordion_hover--bg: transparent;
$figma--accordion--accordion_hover--border: rgba(0, 173, 240, 1);
$figma--accordion--accordion_hover--color: rgba(0, 173, 240, 1);
$figma--accordion--accordion_expand--bg: transparent;
$figma--accordion--accordion_expand--border: rgba(0, 56, 122, 1);
$figma--accordion--accordion_expand--color: rgba(0, 56, 122, 1);

