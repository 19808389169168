.content-area,
#colorbox,
.tooltipster-base {
	li {
		position: relative;
		text-align: left;

		&:first-child {
			margin-top: 0;
		}

		& + li {
			margin: 0.5em 0;
		}
	}

	.report-type--csr & {
		@include breakpoint(xs) {
			ul,
			ol {
				font-size: 14px;
			}
		}
	}

	ul {
		margin-bottom: 1.5em;

		ol {
			li {
				padding-left: 1.5em;
			}
		}

		ul {
			margin-top: 10px;
		}

		
		& > li {
			padding-left: 0.8em;
			position: relative;
			color: $color-dark-cerulean;
			font-weight: 600;

			&:before {
				content: '•';
				position: absolute;
				color: #28AAE1;
				left: 0;
			}

			//Второй уровень вложенности
			& > ul > li {

				&:before {
					content: '-';
					position: absolute;
					background: none;
					top: 0;
					left: 0;
					width: 5px;
					height: 1px;
				}

				//Третий уровень вложенности

				& > ul > li {

					&:before {
						content: '-';
						position: absolute;
						background: none;
						top: 0;
						left: 0;
						width: 5px;
						height: 1px;
					}
				}
			}
		}

		ol {
			counter-reset: list-numbers;

			li {
				&:before {
					background-color: transparent;
				}
			}
		}

		figcaption {
			text-transform: uppercase;
			border-bottom: 1px solid #28AAE1;
			margin-bottom: 15px;
		}
	}

	ul.current-color {
		& > li:before {
			background-color: currentColor;
			border-color: currentColor;
		}
	}

	ol.current-color {
		& > li:before {
			color: currentColor;
		}
	}

	//Список без маркеров
	.no-markers {
		li {
			padding-left: 1.5em;

			&:before {
				display: none;
			}
		}
	}

	//Нумерованный список
	ol {
		counter-reset: list-numbers;
		margin-bottom: 1.5em;

		& > li {
			padding-left: 1.4em;

			&:before {
				content: counters(list-numbers, ".");
				counter-increment: list-numbers;
				display: block;
				width: 20px;
				position: absolute;
				top: 0;
				left: -1px;
				color: $color-dark-cerulean;
				@include font(600);
			}
			//Второй уровень вложенности
			& ol > li {
				padding-left: 1.6em;

				&:before {
					content: counters(list-numbers, ".");
					counter-increment: list-numbers;
					display: block;
					width: 20px;
					position: absolute;
					top: 0;
					left: -4px;
					color: $color-bright-grey;
					@include font(600);
				}
				//Третий уровень вложенности
				& ol > li {
					padding-left: 2.8em;

					&:before {
						content: counters(list-numbers, ".");
						counter-increment: list-numbers;
						display: block;
						width: 20px;
						position: absolute;
						top: 0;
						left: 0;
						color: $color-bright-grey;
						@include font(600);
					}
				}
			}
		}

		ol {
			padding-top: 0;
			margin-top: 10px;
		}
	}

	ol {
		ol li ol li {
			padding-left: 1.7em;

			&:before {
				left: -0.75em;
			}
		}

		& > li {
			ol > li {
				padding-left: 1.8em;
			}
		}
	}

	ul ol,
	ol ul,
	ul ul,
	ol ol {
		margin: 0.7em 0;
	}
}

.list-faq {	
	li {
		color: #58595B !important;
		font-weight: 400 !important;
	}
}

/*.b-table {
	ul {
		& > li {
			&:before {
				top: calc(0.3em * 1.2);
			}
		}

		ul li {
			&:before {
				top: calc(0.35em * 1.2);
			}

			ul li {
				&:before {
					top: calc(0.375em * 1.2);
				}
			}
		}
	}
}*/

@mixin reset-counter($counterName) {
	ol {
		counter-reset: $counterName;

		li {
			&:before {
				content: counters($counterName, ".") !important;
				counter-increment: $counterName !important;
			}
		}
	}

	ul li ol {
		counter-reset: $counterName;

		li {
			&:before {
				content: counters($counterName, ".");
				counter-increment: $counterName;
			}
		}
	}

	ol li ul li {
		&:before {
			text-indent: -9999px;
		}
	}
}

.b-accordion {
	@include reset-counter(list-accordion);
}

.b-table {
	@include reset-counter(list-table);
}

.b-tabs {
	@include reset-counter(list-tabs);
}
