.b-buttons-panel--in-column {
	position: relative;

	.b-buttons-panel__trigger {
		display: block;
		background-color: transparent;
		color: $buttons-panel--trigger-color;
		font-size: 0;
		padding: 0;
		@extend %webfont-button-animation; /* /src/styles/styles/utilities.scss */

		&:before {
			display: block;
			@include font-size($buttons-panel--trigger-icon-size);
		}

		&:hover {
			background-color: transparent;
		}

		&:active,
		&.is-expand {
			background-color: transparent;
		}

		&[disabled] {
			color: $buttons-panel--trigger-color;
		}
	}

	.b-buttons-panel__trigger--tools {
		@include webfont-icon($webfont-icon--tools_2);
		width: 22px;
		color: $color-deep-sky-blue;

		&:hover {
			color: $color-dark-cerulean;
		}

		&.is-expand {
			@include webfont-icon($webfont-icon--close_2);

			&:before {
				font-size: 18px;
				color: $color-deep-sky-blue;
			}
		}

	}

	.b-buttons-panel__list {
		position: relative;
		top: 100%;
		// right: -97px;
		right: -70px;
		margin-top: 24px;
		// z-index: 20;
		z-index: 101;

		@include rem(padding, 22px 20px);
		// box-shadow: 0px 0px 25px rgba($color-black, 0.15);

		@include breakpoint(sm) {
			@include rem(padding-left, 35px);
		}
	}
	
	&.b-buttons-panel--tools .b-buttons-panel__list {
		min-width: 270px;
		
		&:before {
			content: '';
			position: absolute;
			top: -12px;
			// right: 98px;
			// right: -70px;
			right: 70px;
			width: 0;
			height: 0;
			border-left: 10px solid transparent;
			border-right: 10px solid transparent;
			border-bottom: 11px solid $color-deep-sky-blue;
		}
	}

	.b-buttons-panel__title {
		display: inline-block;
		@include font(700, 'ArialRoundedMTProCyr');
		@include font-size($buttons-panel--title-font-size);
		color: $color-deep-sky-blue;
		line-height: 22px;
		@include rem(padding, 0 5px);
		margin-bottom: 13px;
	}

	.b-buttons-panel__item {
		margin: 0;
		padding: 0;
		display: flex;

		&:before {
			content: none;
		}
	}

	.b-buttons-panel__link {
		white-space: nowrap;
		@include rem(padding, 9px 9px 9px 40px);
		@include font-size(14px);

		&--share {
			@include rem(padding-right, 0px);
		}

		&:before {
			@include rem(left, 25px);
			padding-right: 20px;
			color: $buttons-panel--icon-color;
		}

		&:visited {
			&:before {
				color: $buttons-panel--icon-color;
			}
		}

		&:hover,
		&:visited:hover {
			&:before {
				color: $buttons-panel--icon-color-hover;
			}
		}

		&:active,
		&.is-expand,
		&:visited:active {
			&:before {
				color: $buttons-panel--icon-color-active;
			}
		}

	}

	.b-buttons-panel__sublist--share,
	.b-buttons-panel__sublist--social {
		@extend %clearfix;
		// @include rem(padding, 0 10px);
		display: block;
		position: relative;
		right: -15px;
		bottom: -3px;
	}
	.b-buttons-panel__sublist--share {
		display: none;
	}

	.b-buttons-panel__sublist-link--compare {
		text-align: center;
	}

	.b-buttons-panel__sublist:not([data-sublist-slide-type="bottom"]) {
		position: absolute;
		padding: 0;
		top: 0;

		@for $i from 1 through 15 {
			&[data-length="#{$i}"] {
				width: $buttons-panel--sublist-link-width * $i;
			}
		}

		&[data-sublist-slide-type="left"] {
			right: 100%;
		}

		&[data-sublist-slide-type="right"] {
			left: 100%;
		}
	}
}

.b-buttons-panel--in-column.b-buttons-panel--static {
	background-color: $buttons-panel--list-bg-color;
	@include rem(padding-top, $grid-gutter-width);
	@include rem(padding-bottom, $grid-gutter-width);

	.b-buttons-panel__title {
		display: block;
		@include rem(padding-left, $grid-gutter-width);
		@include rem(padding-bottom, $grid-gutter-width);
		@include font-size($buttons-panel--title-font-size);
		color: $buttons-panel--title-color;
	}

	.b-buttons-panel__link {
		white-space: normal;
	}
}

.b-buttons-panel--in-column.b-buttons-panel--compact {
	.b-buttons-panel__list {
		position: absolute;
		display: none;
		background-color: $color-alice-blue;;
	}
}

// Панель с текстом в кнопке тригера
.b-buttons-panel--in-column.b-buttons-panel--button-with-text:not(.b-buttons-panel--download-pdf) {
	@include rem(max-width, 250px);

	.b-buttons-panel__trigger-controller {
		position: relative;
		width: 20px;
		height: 20px;
		margin-left: 14px;

		&:before,
		&:after {
			content: '';
			position: absolute;
			display: block;
			width: 2px;
			height: 14px;
			background-color: currentColor;
			top: 0;
			bottom: 0;
			margin: auto;
			transition: transform .2s ease;
			border-radius: 5px;
		}

		&:before {
			right: 13px;
			transform: rotate(-44deg);
		}

		&:after {
			right: 4px;
			transform: rotate(44deg);
		}
	}

	.b-buttons-panel__trigger {
		display: flex;
		align-items: center;
		width: 100%;
		@include font-size($buttons-panel--trigger-text-font-size);

		&:before {
			margin-right: 9px;
		}

		&.is-expand .b-buttons-panel__trigger-controller {
			&:before {
				transform: rotate(44deg);
			}

			&:after {
				transform: rotate(-44deg);
			}
		}
	}

	.b-buttons-panel__link {
		white-space: normal;
	}

	.b-buttons-panel__list {
		width: 100%;
	}
}

// Панель скачать пдф
.b-buttons-panel--in-column.b-buttons-panel--download-pdf {
	@include rem(max-width, 395px);
	height: 26px;
	margin-top: -1.5px;
	z-index: 10;

	.b-buttons-panel__trigger {
		// @include webfont-icon($webfont-icon--i-pdf);
	}

	.b-buttons-panel__trigger.is-expand {
		// @include webfont-icon($webfont-icon--close_2);
	}

	.b-buttons-panel__trigger--with-text {
		display: flex;
		align-items: center;
		justify-content: center;
		@include font(600);
		font-size: 14px;
		letter-spacing: -0.01em;
		text-transform: uppercase;
		color: $color-dark-cerulean;
		width: 134px;
		height: 26px;
		background-color: $color-white;
		border-radius: 15px;
		border: 1px solid $color-dark-cerulean;

		&:hover,
		&:active,
		&.is-expand {
			color: $color-white;
			background-color: $color-dark-cerulean;
		}

		.b-buttons-panel__trigger-controller {
			display: none;
		}
	}

	.b-buttons-panel__link {
		white-space: normal;
		padding: 0;
	}

	.b-buttons-panel__title {
		// @include rem(padding-left, 7px);
		// margin-bottom: 12px;
		display: none;
	}

	.b-buttons-panel__list {
		@include rem(padding, 3px 17px);
		top: 0;
		right: 0;
		width: 395px;
		height: 26px;
		background-color: transparent;
		border-radius: 15px;
		border: 1px solid $color-deep-sky-blue;
		box-shadow: none;
		margin-top: 0;
		z-index: -1;
		background-color: $color-white;
	}

	.b-buttons-panel__list-inner {
		display: flex;
	}

	.b-buttons-panel__link--pdf-spreads {
		@include webfont-icon($webfont-icon--pdf-spreads);
	}

	.b-buttons-panel__link--pdf-pages {
		@include webfont-icon($webfont-icon--pdf-page);
	}

	.b-button-panel__link,
	.b-buttons-panel__link--pdf-pages,
	.b-buttons-panel__link--pdf-spreads {

		// &:before {
		// 	color: $buttons-panel--icon-color;
		// }

		// &:visited {
		// 	&:before {
		// 		color: $buttons-panel--icon-color;
		// 	}
		// }

		// &:hover,
		// &:visited:hover {
		// 	&:before {
		// 		color: $buttons-panel--icon-color-hover;
		// 	}
		// }

		// &:active,
		// &.is-expand,
		// &:visited:active {
		// 	&:before {
		// 		color: $buttons-panel--icon-color-active;
		// 	}
		// }
	}

	.b-buttons-panel__link {
		font-size: 14px;
		letter-spacing: -0.01em;
		color: $color-deep-sky-blue;
		position: relative;
		margin-right: 13px;

		&:hover {
			color: $color-dark-cerulean;
		}

		&:active {
			color: $color-manatee;
		}

		&:before {
			font-size: 16px;
			padding-right: 0;
			left: 0;
			transform: translate(0, -50%);
		}

		&--pdf-pages {
			padding-left: 18px;
		}

		&--pdf-spreads {
			padding-left: 25px;
		}
	}
}

@include breakpoint(sm) {
	.b-buttons-panel--in-column.b-buttons-panel--compact.b-buttons-panel--download-pdf {
		margin-top: 0;
		max-width: none;
		display: flex;
		justify-content: flex-end;

		.b-buttons-panel__list {
			margin-top: 0;
			right: 16px;
		    width: 420px;
		    height: 26px;
		    z-index: -1;
		    font-size: 13px;
		    display: block;

			&:before {
				display: none;
			}
		}

		.b-buttons-panel__list-inner {
			// box-shadow: 0px 15px 25px -15px rgba($color-black, 0.15);
			padding-bottom: 25px;
		}

		.b-buttons-panel__link {
			@include font-size(16px);
		}
	}

	.b-buttons-panel.b-buttons-panel--in-column.b-buttons-panel--tools {
		.b-buttons-panel__list {
			right: -60px;
			margin-top: 24px;

			&:before {
				right: 61px;
			}
		}
	}

	.b-buttons-panel--in-column .b-buttons-panel__trigger {
		color: $color-dark-cerulean;
	}

	.b-buttons-panel--in-column .b-buttons-panel__trigger:before {
		font-size: 20px;
	}
}

@include breakpoint(xs) {
	.b-buttons-panel--in-column.b-buttons-panel--compact {
		.b-buttons-panel__sublist--share {
			width: auto;
			height: auto;
		}

		.b-buttons-panel__sublist-link {
			float: none;
		}

		&.b-buttons-panel--download-pdf {

			.b-buttons-panel__trigger--with-text {
				font-size: 12px;
				width: 90px;
				height: 23px;
			}

			.b-buttons-panel__item {
				padding: 0 8px 0 0;
				width: calc((100% - 70px) / 2);
			}

			.b-buttons-panel__link {
				font-size: 13px;
				line-height: 20px;
				padding-left: 0;
				margin-right: 0;
				color: $color-deep-sky-blue;

				&:before {
					display: none;
				}
			}

			.b-buttons-panel__list {
			    width: auto;
			    left: 0;
			    padding: 0 13px;
				height: 23px;
			}

			.b-buttons-panel__list-inner {
				padding-bottom: 0;
			}
		}
	}
}
