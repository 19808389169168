.l-layout-wrapper--1 {
	max-width: none;

	.browser-ie & {
		height: 100%;
	}

	.l-layout-wrapper__header {
		height: 64px;
		margin-bottom: 0;
	}

	.l-layout-wrapper__header.is-fixed {
		position: static;
		top: 0;
		z-index: 10;
		width: $site-width;
	}

	.l-header {
		&__inner {
			padding: 15px 0;
			padding-right: 84px;
		}
	}

	.l-header-top-line {
		display: flex;
		align-items: center;
		justify-content: space-between;
		padding-top: 5px;

		&__menu-title {
			width: 100%; //ширина меню
			max-width: 440px;
			z-index: 11;
			margin-right: 35px;
			color: #00377B;
			text-transform: uppercase;
			font-size: 14px;
			line-height: 18px;
			font-weight: 600;
			padding-left: 16px;
			&:after {
				content: '';
				position: absolute;
				width: 100%; //ширина меню
				max-width: 440px;
				top: 0;
				left: 0;
				height: 65px;
				background-color: #E1F4FD;
				border-right: 1px solid #28AAE1;
				z-index: -1;
			}
		}

		&__logo {
			order: 1;
			margin-right: 19px;
			margin-top: -5px;
		}

		&__title {
			order: 2;
			flex-shrink: 0;
			flex-grow: 0;
			color: $color-dark-cerulean;
			margin-right: auto;
			// flex-basis: 300px;
			// width: 300px;
		}

		&__tools {
			order: 3;
			display: flex;
			justify-content: flex-end;
			height: 100%;
			// flex-grow: 1;
		}
	}

	.l-header-top-nav {
		display: flex;

		&__logo {
			flex-shrink: 0;
			margin-right: 12px;
		}

		&__menu {
			flex-grow: 1;
			flex-shrink: 1;
		}
	}

	.l-page {

		&__content {
			padding-right: 150px;
			padding-left: 30px;
		}

		&__sidebar {
			width: 338px;
			flex-basis: 338px;
		}

		&__content,
		&__sidebar {
			padding-top: 38px;
		}

		&__nav-breadcrumbs {
			width: 100%;
			max-width: calc(100% - 90px); // минус ширина page-nav'a
		}

		&__content--wide {
			padding-right: 0;
			padding-left: 0;
		}
	}

	.content-area {
		// position: relative;

		// &:before {
		// 	content: "";
		// 	display: block;
		// 	position: absolute;
		// 	right: 0;
		// 	height: 100%;
		// 	top: 0;
		// 	width: 1px;
		// 	background: $color-white-smoke;

		// }
	}

	.l-page__content--wide .content-area:before {
		display: none;
	}


	// .l-page-nav {
		// height: auto !important;
		// min-height: auto !important;
		// position: relative;

		// &:after {
		//     content: "";
		//     display: block;
		//     position: absolute;
		//     left: 50%;
		//     width: 100vw;
		//     margin-left: -50vw;
		//     top: -2px;
		//     height: 2px;
		//     background: $color-dark-cerulean;
		// }
	// }

	.l-buttons-area {
		display: flex;
		align-items: center;

		&__item + .l-buttons-area__item {
			@include rem(margin-left, 27px);
			flex-shrink: 0;
		}

		&__item + .l-buttons-area__mobile-nav-panel {
			@include rem(margin-left, 12px);
		}

		&__search {
			.search {
				width: auto;
				display: block;
			}
		}

		&__mobile-nav-panel {
			display: none;
		}

		&__sitemap {
			.b-custom-links__item {
				padding-left: 0;
			}

			.b-custom-links__link {
				color: $color-deep-sky-blue;
				font-size: 24px;
				line-height: 0;

				&:hover {
					color: $color-dark-cerulean;
				}

				&--static {
					color: $color-bright-grey;

					&:hover {
						color: $color-bright-grey;
					}
				}
			}

			.b-custom-links__link-icon {
				@include webfont-icon($webfont-icon--tree);
			}
		}
	}

	.l-buttons-area__pdf {
		.button-download-pdf {
			display: flex;
			align-items: center;
			justify-content: center;
			@include font(600);
			font-size: 14px;
			letter-spacing: -0.01em;
			text-transform: uppercase;
			color: $color-dark-cerulean;
			width: 134px;
			height: 26px;
			background-color: $color-white;
			border-radius: 15px;
			border: 1px solid $color-dark-cerulean;
			text-decoration: none;

			&:hover,
			&:active,
			&.is-expand {
				color: $color-white;
				background-color: $color-dark-cerulean;
			}

			&:active {
				transform: translateY(1px);
			}
		}
	}
}

.l-footer {
	display: flex;
	// background: $color-summer-sky;
	background: #00377B;
	@include rem(padding-top, 16px);
	@include rem(padding-bottom, 16px);
	overflow: hidden;

	// @media (max-width: 1680px) {
	// 	overflow: hidden;
	// }

	&::before {
		content: '';
		position: absolute;
		width: 100vw;
		height: 100%;
		top: 0;
		left: 50%;
		margin-left: -50vw;
		// background-color: $color-summer-sky;;
		background-color: #00377B;
		z-index: 0;
	}

	&__inner {
		padding: 0 100px 0 20px;
		position: relative;
		z-index: 1;
		display: flex;
		align-items: center;
		justify-content: space-between;
		width: 100%;
		max-width: 1480px;
		margin: 0 auto;
		position: relative;
	}

	// &__nav {
	// 	margin-left: auto;

	// 	&--applications {
	// 		text-align: left;
	// 		margin-right: auto;
	// 		margin-left: 0;
	// 	}
	// }

	// &__copyright {
	// 	margin-right: 30px;
	// }

	// &__social {

	// }
}

@if ($responsive) {
	.l-layout-wrapper--1 {
		.l-layout-wrapper__header.is-fixed {
			width: 100%;
			max-width: $site-width;
			min-width: $site-min-width;
		}
	}

	@include breakpoint(lg) {
		.l-layout-wrapper--1 {
			.l-page {

				&__content {
					padding-right: 100px;

					&--wide {
						padding-right: 0;
					}
				}

				&__sidebar {
					width: 288px;
					flex-basis: 288px;
				}
			}

			.content-area {

				&:before {
					right: 0;
				}
			}
		}
	}

	@media (max-width: 1200px) and (min-width: 1150px) {
		.l-page {
			padding-top: 115px;
		}

		.l-page__content .l-page__nav {
			display: none;
		}
	}

	@media (max-width: 1480px) {
		.l-layout-wrapper--1 {
			.l-header-top-line__menu-title {
				margin-right: 0;
				max-width: 300px;
				&:after {
					max-width: 300px;
				}
			}
		}
	}

	@media (max-width: 1280px) {
		.l-layout-wrapper--1 {

			.l-header-top-nav {
				&__menu {
					display: none;
				}
			}

			.l-header-top-line {
				&__menu-title {
					display: none;
				}
			}

			.l-buttons-area__mobile-nav-panel {
				display: block;
				height: 100%;
				border-left: 1px solid #28AAE1;
			}

			.l-header {
				&__inner {
					padding: 0;
					height: 100%;
				}
			}

			.l-header-top-line {
				padding: 0;
			}

			.l-footer {
				&__inner {
					padding: 0 20px;
				}
			}
		}
	}

	@include breakpoint(md) {
		.l-layout-wrapper--1 {

			// .l-header-top-line__menu-title {
			// 	max-width: 300px;
			// 	&:after {
			// 	    max-width: 300px;
			// 	}
			// }

			.l-header-top-line__logo {
				display: block;
			}

			.l-header-top-nav__logo {
				display: none;
			}

			.l-page__content {
				width: 100%;
			}

			.l-page {
				flex-direction: column;

				.browser-ie & {
					display: block;
				}
			}

			.l-page__sidebar {
				flex-basis: auto;
				width: auto;
			}

			.l-page {

				&__content {
					padding-top: 69px;
					padding-right: 0;
					padding-left: 0;
				}
			}

			.l-page__nav {
				margin-bottom: 0;
			}

			.l-page__nav-breadcrumbs {
				width: 100%;
				display: none;
			}

			.l-page__nav-controls {
				flex-basis: auto;
				width: auto;
			}

			// .l-header {
			// 	&__inner {
			// 		padding: 18px 0;
			// 	}
			// }

			.l-header-top-line {
				padding: 0;
				height: 100%;
			}

			.l-header-top-line__title {
				// max-width: 195px;
				font-size: 13px;
			}

			.l-header-top-line__logo {
				margin-left: 0;
			}

			.l-buttons-area__mobile-nav-panel {
				// display: block;
			}

			.l-buttons-area__item + .l-buttons-area__item {
				// margin-left: 16px;
			}

			.content-area {

				&:before {
					display: none;
				}
			}
		}
	}

	// @media(max-width: 600px) {
	// 	.l-layout-wrapper--1 {
	// 		.l-footer__inner {
	// 			display: flex;
	// 			flex-direction: column;
	// 			align-items: initial;
	// 		}


	// 		.l-footer__copyright {
	// 			display: flex;
	// 			align-self: center;
	// 			margin: 0 auto 15px;
	// 		}

	// 		.l-footer__nav {
	// 			order: 2;
	// 			@include rem(margin-bottom, 10px);
	// 		}

	// 		.l-footer__social {
	// 			position: absolute;
	// 			right: 20px;
	// 			bottom: 16px;

	// 			.b-social__item {
	// 				padding: 0;
	// 			}
	// 		}

	// 		.b-custom-links--inline .b-custom-links__item {
	// 			margin-right: 10px;
	// 		}
	// 	}
	// }

	@media (max-width: 1100px) and (min-width: 1000px) {
		.l-page {
			padding-top: 115px;
		}

		.l-page__content .l-page__nav {
			display: none;
		}
	}

	@media (max-width: 930px) and (min-width: 890px) {
		.l-page {
			padding-top: 115px;
		}

		.l-page__content .l-page__nav {
			display: none;
		}
	}

	@media (max-width: 890px) and (min-width: 787px) {
		.l-footer__inner {
 			display: flex;
 			flex-wrap: wrap;
 			justify-content: center;
 			padding: 0 20px 0 20px;
 		}
 	}

 	@media (max-width: 890px) {
 		.l-footer__nav {
 			order: 2;
 			margin: 0 12px 10px 12px;
 			// margin-left: unset;
 			// margin-right: 16px;

			// &--applications {
			// 	order: 2;
			// 	margin-right: unset;
   //  			margin-left: unset;
			// }
		}

		.l-footer__copyright {
			order: 4;
 			margin: 0 12px 10px 12px;
 		}

 		.l-footer__social {
 			order: 1;
 			margin: 0 12px 10px 12px;

 			&--shareholders {
 				order: 3;
 			}
		}
 	}

	@include breakpoint(sm) {
		.l-layout-wrapper--1 {

			.l-layout-wrapper__header {
				// height: 42px;

				&:after {
					height: 1px;
				}
			}

			// .l-header {
			// 	&__inner {
			// 		padding: 6px 0;
			// 	}
			// }

			.l-buttons-area__pdf,
			.l-buttons-area__search,
			.l-buttons-area__sitemap,
			.l-buttons-area__menu {
				display: none;
			}

			.l-header-top-line__title {
				display: none;
				position: absolute;
				top: 100%;
				left: 0;
				max-width: 100%;
				width: 100%;
				z-index: 1;
				background-color: $color-white;
				// margin-top: 1px;
				margin-top: 0;
			}

			.l-header-top-line__logo {
				margin-top: -20px;
			}

			// .l-buttons-area__mobile-nav-panel {
			// 	display: block;
			// }

			.l-page__nav {
				flex-direction: column;
				align-items: flex-start;
			}

			.l-page__nav-breadcrumbs {
				max-width: 100%;
				@include rem(margin-bottom, 10px);
			}

			.l-page__nav-controls {
				flex-basis: auto;
				width: auto;
			}
		}

		.l-footer__inner {
 			display: flex;
 			flex-wrap: wrap;
 			// justify-content: space-between;
 			padding: 0 20px 0 20px;
 		}

		.l-footer__nav {
 			order: 2;
 			margin: 0 12px 10px 12px;
 			// margin-left: unset;
 			// margin-right: 16px;

			// &--applications {
			// 	order: 2;
			// 	margin-right: unset;
   //  			margin-left: unset;
			// }
		}

		.l-footer__copyright {
			order: 4;
 			margin: 0 12px 10px 12px;
 		}

 		.l-footer__social {
 			order: 1;
 			margin: 0 12px 10px 12px;

 			&--shareholders {
 				order: 3;
 			}
		}

		.l-page {
			padding-top: 84px;
		}
	}

	@media (max-width: 567px) {
		.l-layout-wrapper--1 {
			.l-footer__inner {
	 			justify-content: center;
	 		}
		}
	}

	@include breakpoint(xs) {
		.l-layout-wrapper--1 {
			.l-page__content {
				padding-top: 30px;
			}

			.l-footer__inner {
	 			justify-content: center;
				flex-direction: column;
	 		}

			 .l-footer__nav {
				margin-left: 0;
				margin-right: 0;
			}
		}
	}
}

.body--strategy {
	.l-layout-wrapper--1 {
		.content-area:before {
			display: none;
		}
	}
}
