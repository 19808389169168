$tabs--button-bg: $color-white;
$tabs--button-bg-active: $color-white;
$tabs--button-bg-hover: $color-white;
$tabs--button-text-color: $color-bright-grey;
$tabs--button-text-color-hover: $color-deep-sky-blue;
$tabs--button-text-color-active:  $color-dark-cerulean;
$tabs--body-bg: $color-white;

.b-tabs {
	margin-bottom: 57px;

	&__nav {
		display: flex;		
	}

	&__button {
		@extend %disable-visited;
		@extend %collapse--top;
		display: flex;
		flex-grow: 1;
		flex-shrink: 1;
		justify-content: center;
		align-items: center;
		background-color: $tabs--button-bg;
		@include rem(padding, 15px 35px);
		margin: 10px 14px 10px 0px;
		position: relative;
		text-decoration: none;
		color: #435664;
		text-align: center;
		height: 100%;
		@include font-size(17px);
		line-height: 24px;
		@include font(600, 'VTBGroup');
		opacity: 0.5;
		border: 1px solid #435664;
		border-radius: 15px;

		transition: all .2s ease-in-out;

		&:visited {
			color: $tabs--button-text-color;
		}

		&:hover {
			opacity: 1;
			color: #435664;
			border: 1px solid #435664;
			background-color: $tabs--button-bg-hover;

			&:visited {
				color: #435664;
				background-color: $tabs--button-bg-hover;
			}
		}

		&.is-expand,
		&:active {
			opacity: 1;
			border: 1px solid #05AADA;
			color: $tabs--button-bg;
			background-color: #05AADA;

			&:visited {
				color: $tabs--button-bg;
				background-color: #05AADA;

			}
		}
	}

	&__nav-item {
		border-left: 1px solid $color-white;
		

		@include breakpoint(sm) {
			flex-basis: 100%;
		}

		&:first-child {
			border-left: none;
		}
	}

	&__body {
		@extend %collapse--top;
		background-color: $tabs--body-bg;
		width: 100%;
	}

	&__article {
		@extend %collapse--top;
		position: absolute;
		left: -99999px;
		top: -99999px;
		@include rem(padding, 60px 16px 16px 16px);
		border: none;

		&.is-expand {
			position: relative;
			left: 0;
			top: 0;
		}
	}
}