$mainpage--corner-color: #0144AD;
$mainpage--right-color: #28AAE1;

.browser-ie {
	.body--mainpage{
		.l-layout-wrapper__page {
			.l-page__content {
				height: 100%;
			}
		}
	}
}

.body--mainpage {
	.l-layout-wrapper {
		max-width: 100%;

		.browser-ie & {
			height: 100%;
		}

		&__header.l-header {
			display: none;
		}

		&__menu {
			display: none;
		}

		&__footer.l-footer {
			display: none;
		}

		.l-page {
			padding: 0;
		}

		.content-area {
			height: 100%;
		}

		.l-header__inner,
		.l-footer__inner,
		.l-page__nav,
		.b-slider-button-wrapper {
			max-width: $site-width;
			margin: auto;
		}

		.l-layout-wrapper__page.l-page {
			flex-direction: row;
		}

		.l-page__content {
			padding-bottom: 0;
			padding-left: 0;
			padding-right: 0;
			padding-top: 0;
		}
	}

	.mainpage-wrapper {
		position: relative;
		width: 100%;
		height: 100%;
		background: url(/images/components/mainpage/images/main.jpg) no-repeat 72.5% 10% local;
		background-size: cover;
		display: flex;

		.mainpage {
			&__right {
				position: absolute;
				right: 0;
				top: 0;
				width: 15px;
				height: 100%;

				background-color: $mainpage--right-color;
			}

			&__corner {
				position: absolute;
				left: -300px;
				top: -220px;
				width: 405px;
				height: 100%;

				transform: rotate(200deg);
				background-color: $mainpage--corner-color;
			}

			&__content {
				position: relative;
				align-self: center;
				justify-self: flex-start;
				padding-left: 200px;

				h1 {
					color: $color-white;
					@include font(500, 'Roboto');
					// @include font-size(84px);
					@include font-size(90px);
					line-height: 1em;
					margin-bottom: 10px;

					text-transform: uppercase;

					&:after {
						content: none;
					}
				}

				h2 {
					color: $mainpage--right-color;
					@include font(300, 'Roboto');
					// @include font-size(66px);
					@include font-size(72px);
					font-weight: 300;
					line-height: 1em;
					margin-bottom: 50px;

					text-transform: uppercase;
				}

				span {
					color: $color-white;
					@include font(300, 'Roboto');
					// @include font-size(24px);
					@include font-size(30px);
					display: block;
					text-transform: uppercase;
					margin-bottom: 50px;
					margin-left: 2em;

					&::before {
						content: "//";
						color: $mainpage--right-color;
						@include font(500, 'Roboto');
						@include font-size(36px);

						margin-right: 0.25em;
					}
				}

				.mainpage-link {
					color: $color-white;
					position: relative;
					display: block;
					text-transform: uppercase;
					@include font(400, Roboto);
					// @include font-size(18px);
					@include font-size(20px);
					margin-bottom: 3em;
					margin-left: 44px;

					&:before {
						content: '';
						position: absolute;
						left: -44px;
						top: 0;
						width: 26px;
						height: 24px;
						background-image: url('/images/components/mainpage/images/link.svg');
					}
				}
			}

			&__logo {
				position: absolute;
				width: 200px;
				right: 70px;
				top: 50px;
			}
		}
	}

	.mp-animated {
		opacity: 0;
		transform: translateX(-10%);
		transition-property: transform, opacity;
		transition-duration: 0.5s;
		transition-timing-function: linear;
		transition-delay: 0.5s;

		&--active {
			opacity: 1;
			transform: translateX(0);
		}
	}

	.link-inactive {
		&:hover {
			cursor: default;
		}
	}
}

@media screen and (max-width: 1280px) {
	.body--mainpage {
		.mainpage-wrapper {
			.mainpage {
				&__content {
					h1 {
						@include font-size(76px);
					}

					h2 {
						@include font-size(56px);
					}

					span {
						@include font-size(24px);
					}

					.mainpage-link {
						@include font-size(18px);
						margin-bottom: 2em;
					}
				}

				&__logo {
					right: 120px;
					top: 80px;
				}
			}
		}
	}
}

@include breakpoint(lg) {
	.body--mainpage {
		.mainpage-wrapper {
			overflow: hidden;
			background-color: #032678;
			background-image: none;

			.mainpage {
				&__bg {
					width: calc(100% + 170px);
					height: 100%;
					background: url(/images/components/mainpage/images/main.jpg) no-repeat 78.5% 100% local;
					background-size: cover;
					position: absolute;
					left: 7px;
					top: 63px;
				}

				&__bg-blur {
					width: 100%;
					height: 50%;
					background-color: rgba(3, 38, 120, 0.8);
					filter: blur(30px);
					position: absolute;
					top: -30%;
					left: 0;
				}
			}
		}
	}
}

@include breakpoint(md) {
	.body--mainpage {
		.mainpage-wrapper {
			.mainpage {
				&__bg {
					width: calc(100% + 170px);
					height: 100%;
					background: url(/images/components/mainpage/images/main.jpg) no-repeat 78.5% 100% local;
					background-size: cover;
					position: absolute;
					left: 7px;
					top: 63px;
				}

				&__corner {
					width: 320px;
				}

				&__content {
					padding-left: 100px;

					h1 {
						@include font-size(60px);
					}

					h2 {
						@include font-size(52px);
					}

					.mainpage-link {
						margin-bottom: 2em;
					}
				}
			}
		}
	}
}

@include breakpoint(sm) {
	.body--mainpage {
		.mainpage-wrapper {
			.mainpage {
				&__bg {
					width: 100%;
					height: calc(100% + -128px);
					background: url(/images/components/mainpage/images/main.jpg) no-repeat 78.5% 100% local;
					background-size: cover;
					position: absolute;
					left: 0;
					top: 200px;
				}

				&__corner {
					position: absolute;
					left: -180px;
					top: -360px;
					width: 200px;
					height: 100%;
				}

				&__content {
					align-self: auto;
					padding-top: 100px;
					padding-left: 70px;

					h1 {
						@include font-size(45px);
						margin-bottom: 2px;
					}

					h2 {
						@include font-size(36px);
						margin-bottom: 20px;
					}

					span {
						@include font-size(18px);
						margin-bottom: 25px;
						margin-left: 2em;

						&::before {
							@include font-size(24px);
						}
					}

					.mainpage-link {
						@include font-size(14px);
						margin-bottom: 2em;
						margin-left: 35px;

						&:before {
							content: '';
							position: absolute;
							left: -35px;
						}
					}
				}

				&__logo {
					width: 90px;
					right: 50px;
					top: 30px;
				}
			}
		}
	}
}

@include breakpoint(xs) {
	.body--mainpage {
		.mainpage-wrapper {
			.mainpage {
				&__content {
					padding-left: 27px;

					h1 {
						@include font-size(32px);
					}

					h2 {
						@include font-size(24px);
					}

					span {
						@include font-size(14px);

						&::before {
							@include font-size(18px);
						}
					}

					.mainpage-link {
						@include font-size(12px);
					}
				}
			}
		}
	}
}

[data-popup-id="mainpage-popup"] {
	position: relative;

	.b-popup__inner {
		justify-content: flex-start;
	}

	.b-popup__content {
		padding: 10px;
		padding-right: 36px;
		background-color: $color-summer-sky;
		max-width: 400px;
	}

	.b-popup__close {
		color: $color-white;
	}

	.mainpage-popup {
		&__row {
			display: flex;
			margin-bottom: 10px;
		}

		&__text {
			color: $color-white;
			width: 80%;
		}

		&__img {
			margin-left: 5px;
			width: 20%;
		}

		&__shares {
			display: none;
		}

		&__btn {
			display: inline-block;
			background-color: $color-white;
			border-radius: 10px;
			padding: 10px 15px;
			color: $color-summer-sky;

			&::before {
				content: none;
			}
		}
	}

	@include breakpoint(sm) {
		.b-popup__inner {
			width: 100%;
			margin: 0;
		}

		.mainpage-popup__text {
			width: 70%;
		}

		.mainpage-popup__img {
			width: 30%;
		}

		.mainpage-popup__qrcode {
			display: none;
		}

		.mainpage-popup__shares {
			display: block;
		}
	}

	@include breakpoint(xs) {
		.b-popup__content {
			max-width: 80vw;
			min-width: 260px;
		}
	}
}
