.b-sitemap {
	.b-sitemap__item {
		&:before {
			display: none;
		}
	}

	.b-sitemap__item--lvl1 {
		padding-left: 0;

		.b-sitemap__item {
			padding-left: 1.2em;
		}
	}

	&__link {
		display: inline;
		text-decoration: none;
		color: $link--fn-default-color;

		&:visited {
			color: $link--fn-default-color;
		}

		&:hover,
		&:visited:hover {
			color: $link--fn-hover-color;
		}

		&:active,
		&:visited:active {
			color: $link--fn-active-color;
		}
	}

	&__link--lvl1 {
		font-size: 1.15em;
		font-weight: bold;
	}

	&__link--lvl2 {
		font-size: 0.95em;
	}

	&__link--lvl3 {
		font-size: 0.85em;
	}

	&__link--lvl4 {
		font-size: 0.8em;
	}

	&__list--lvl1 {
		& > .b-sitemap__item {
			margin-bottom: 1.55em;
		}
	}
}
