.lead {

	@include font(600, 'VTBGroup');
	font-size: 18px;
	line-height: 25px;
	// color: $color-dark-cerulean;
	color: $color-deep-sky-blue;
	margin: 0 0 20px;
	max-width: 80%;

	&.color-prussian-blue {
		color: $color-prussian-blue;
	}

	&--dark {
		font-size: 16px;
		line-height: 24px;
		color: #0E4E96;
		// text-transform: uppercase;
		font-weight: bold;
	}

	@include breakpoint(xs) {
		max-width: 100%;
	}

	@include breakpoint(xs) {
		@include font-size(14px);
		line-height: 20px;
		margin: 0 0 15px;
	}

}
