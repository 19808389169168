$bc-link-color: $link-default-color;
$bc-link-color-hover: $link-hover-color;
$bc-link-color-active: $link-active-color;
$bc-link-color-visited: $bc-link-color;

$bc-home-icon-color: #444444;
$bc-home-icon-color-hover: $link-hover-color;
$bc-home-icon-color-active: $link-active-color;

$bc-item-color: black;
$bc-dash-color: $color-ironside-gray;

$bc-sub-bg: #cdcdcd;
$bc-sub-link-color: $link-default-color;
$bc-sub-link-color-hover: $link-hover-color;

@if ($contrast) {
	$bc-link-color: $link-default-color;
	$bc-link-color-hover: $link-hover-color;
	$bc-link-color-active: $link-active-color;
	$bc-link-color-visited: $bc-link-color;

	$bc-home-icon-color: #444444;
	$bc-home-icon-color-hover: $link-hover-color;
	$bc-home-icon-color-active: $link-active-color;

	$bc-item-color: black;
	$bc-dash-color: $color-ironside-gray;

	$bc-sub-bg: #cdcdcd;
	$bc-sub-link-color: $link-default-color;
	$bc-sub-link-color-hover: $link-hover-color;
}

.breadcrumbs {
	@extend .no-markers;
	margin-top: 0;
	@include font-size(14px);

	ul.breadcrumbs-sub {
		display: none;
		position: absolute;
		white-space: nowrap;
		@include rem(padding, 2px 5px);
		color: $bc-sub-link-color;
		background-color: $bc-sub-bg;
		margin-top: 0;
		z-index: 11;

		li.breadcrumbs-sub__item {
			margin-top: 0;
			padding-left: 0;

			& + .breadcrumbs-sub__item {
				@include rem(margin-top, 4px);
			}
		}

		&__link {
			color: $bc-sub-link-color;

			&:visited {
				color: $bc-sub-link-color;
			}
		}
	}

	ul.breadcrumbs__list {
		margin-top: 0;
	}

	&__item {
		position: relative;
		display: inline-block;
		color: $bc-item-color;
		vertical-align: middle;

		& + .breadcrumbs__item {
			@include rem(padding-left, 24px);

			&:after {
				content: '';
				position: absolute;
				top: 8px;
				width: 10px;
				height: 2px;
				background-color: $bc-dash-color;
				left: 7px;
			}
		}
	}

	&__link {
		color: $bc-link-color;

		&:visited {
			color: $bc-link-color-visited;
		}

		&:active {
			color: $bc-link-color-active;
		}
	}

	&__link--home {
		position: relative;
		display: block;
		color: $bc-home-icon-color;
		font-size: 0;
		margin-bottom: 2px;
		@include webfont-icon($webfont-icon--i-home);
		text-decoration: none;

		&:before {
			@include font-size(14px);
		}

		&:active {
			color: $bc-home-icon-color-active;
		}
	}
}
