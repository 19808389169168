blockquote {
	position: relative;
	background-color: transparent;
	// padding: 16px 0;
	// border-top: 1px solid $color-dark-cerulean;
	// border-bottom: 1px solid $color-dark-cerulean;
	@include rem(padding, 38px 101px 40px 122px);
	background-color: $color-alice-blue-lighter;
	margin-bottom: 32px;

	@include webfont-icon($webfont-icon--i-quote-before);
	@include webfont-icon($webfont-icon--i-quote-after, after);

	&:before,
	&:after {
		position: absolute;
		display: block;
		@include font-size(34px);
		color: $color-dark-cerulean;
	}

	&:before {
		top: 38px;
    	left: 47px;
	}

	&:after {
		right: 50px;
    	bottom: 40px;
	}


	&.quote-photo {
		display: flex;
		padding: 8px 0;
		border: none;
	}

	div {
		position: relative;
		padding: 0 0 0 32px;
		display: flex;
    	flex-direction: column;
    }

	img {
		flex-shrink: 0;
		width: 20%;
		display: block;
		object-fit: contain;
		object-position: top;
	}

	p {

		font-weight: normal;
		font-size: 18px;
		line-height: 24px;
		color: $color-dark-cerulean;
		margin-bottom: 1.3em;
	}

	cite {
		display: flex;
		flex-direction: column;
		color: $color-bright-grey;
		font-size: 16px;

		span {
			display: block;
			font-weight: 600;
			font-size: 18px;
			color: $color-dark-cerulean;
		}
	}

	@include breakpoint(sm) {

		&.quote-photo {
			padding: 0;
		}

		img {
			width: 25%;
		}
	}

	@include breakpoint(xs) {
		@include rem(padding, 38px);

		&:before {
			font-size: 22px;
			top: 14px;
			left: 7px;
		}

		&:after {
			font-size: 22px;
			right: 7px;
			bottom: 14px;
		}

		&.quote-photo {
			display: block;
		}

		p {
			line-height: 28px;
		}

		img {
			width: 60%;
			margin-bottom: 25px;
			object-position: left;
		}

		div {
			position: relative;
			padding: 0 0 28px;
		}
	}

	.row-no-padding {
		padding: 0;
	}

	.col-xs-no-padding {
		@include breakpoint(xs) {
			padding: 0;
		}
	}

	.report-type--csr & {
		p {
			font-size: 16px;

			@include breakpoint(xs) {
				font-size: 14px;
			}
		}
	}
}



// @include breakpoint(xs) {
// 	blockquote {
// 		padding: 24px 16px 24px 40px;

// 		.text {
// 			&:before {
// 				left: -28px;
// 			}
// 		}
// 	}


// }
