$title--text-color: $color-dark-cerulean;
$title--link-color: $color-hit-grey;
$title--link-color--hover: $color-deep-sky-blue;
$title--link-color--active: $color-dark-cerulean;

@if ($contrast) {
	$title--text-color: $color-white;
}

.b-report-title {
	@include font-size(14px);
	@include font(400);
	color: $title--text-color;
	text-transform: uppercase;

	a {
		@include font-size(14px);
		@include rem(line-height, 18px);
		@include font(400);
	}

	&__link {
		color: $title--link-color;
		text-decoration: none;

		&:visited {
			color: $title--link-color;
		}

		&:hover,
		&:visited:hover {
			color: $title--link-color--hover;
		}

		&.active,
		&.active:hover,
		&:active,
		&:visited:active {
			color: $title--link-color--active;
		}
	}
}

@if ($responsive) {
	@include breakpoint(md) {
		.b-report-title {

			a {
				@include font-size(13px);
				line-height: 17px;
			}
		}
	}

	@include breakpoint(sm) {
		.b-report-title {
			display: none;
			// font-size: 0;
			// display: flex;

			// &__link {
			// 	display: flex;
			// 	align-items: center;
			// 	justify-content: center;
			// 	@include rem(padding, 8px);
			// 	flex: 0 0 50%;
			// 	min-height: 42px;
			// 	border-left: 1px solid $color-deep-sky-blue;
			// 	border-bottom: 1px solid $color-deep-sky-blue;

			// 	&:last-child {
			// 		border-right: 1px solid $color-deep-sky-blue;
			// 	}

			// 	&:active,
			// 	&:visited:active,
			// 	&.active {
			// 		background: $color-deep-sky-blue;
			// 		color: $color-white;
			// 	}
			// }

			// a {
			// 	@include font-size(12px);
			// 	line-height: 15px;
			// }
		}
	}
}