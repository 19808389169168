$mobile-menu_vertical-accordeon--font-size: 16px;
$mobile-menu_vertical-accordeon--button-width: 30px;               // The width of the button area
$mobile-menu_vertical-accordeon--button-height: 30px;                   // The height of the button area
$mobile-menu_vertical-accordeon--bar-thickness: 3px;                    // The thickness of the button bars
$mobile-menu_vertical-accordeon--bar-space: 3px;                 // The spacing between button bars
$mobile-menu_vertical-accordeon--transistion-duration: 0.3s;     // The transition duration
$mobile-menu_vertical-accordeon--bar-width: 28px;

$mobile-menu_vertical-accordeon--panel-bg: $color-eallery;
$mobile-menu_vertical-accordeon--link-color: #00377b;
$mobile-menu_vertical-accordeon--link-color-hover: #009EE2;
$mobile-menu_vertical-accordeon--link-color-active: #00377b;
$mobile-menu_vertical-accordeon--controller-color: $base-text-color;
$mobile-menu_vertical-accordeon--controller-color-hover: $color-black;
$mobile-menu_vertical-accordeon--controller-color-active: orange;
$mobile-menu_vertical-accordeon--button-bg: transparent;
$mobile-menu_vertical-accordeon--button-bg-hover: transparent;
$mobile-menu_vertical-accordeon--button-bg-active: transparent;
$mobile-menu_vertical-accordeon--button-color: #00377b;
$mobile-menu_vertical-accordeon--button-color-hover: #00aeef;
$mobile-menu_vertical-accordeon--button-color-active: #00377b;
$mobile-menu_vertical-accordeon--link-lvl1-bg: transparent;
$mobile-menu_vertical-accordeon--link-lvl2-bg: transparent;
$mobile-menu_vertical-accordeon--link-lvl3-bg: transparent;

.b-mobile-menu.b-mobile-menu--vertical-accordion {
	position: fixed;
    left: 0;
    padding-left: 15px;
    width: 100%;
 //    left: auto!important;
 //    width: auto!important;
 //    max-width: 25rem;
 //    z-index: 22;
 //    z-index: 10;
 //    top: 75px;
	// @include rem(width, $mobile-menu_vertical-accordeon--button-width);
	// @include rem(height, $mobile-menu_vertical-accordeon--button-height);
	// transform: translateX(100%);
	// transform: translateX(0);
 //    transition: transform .3s ease-in-out;

	&.is-expand {
		transform: translateX(0);
	}

	.b-mobile-menu__panel-item {
		&--mobile {
			display: none;
		}
	}

	.b-mobile-menu__header {
		display: none;
	}

	.b-mobile-menu__burger {
		// padding: 1rem 1rem;
		padding: 0;
		display: inline-block;
		cursor: pointer;
		-webkit-transition-property: opacity,-webkit-filter, transform;
		transition-property: opacity,-webkit-filter, transform;
		-o-transition-property: opacity,filter, transform;
		transition-property: opacity,filter, transform;
		transition-property: opacity,filter,-webkit-filter, transform;
		-webkit-transition-duration: .15s;
		-o-transition-duration: .15s;
		transition-duration: .15s;
		-webkit-transition-timing-function: linear;
		-o-transition-timing-function: linear;
		transition-timing-function: linear;
		font: inherit;
		color: inherit;
		text-transform: none;
		background-color: #f8f9fa;
		border: 0;
		margin: 0;
		overflow: visible;
		// display: block;
		display: none;
		z-index: 10;
		position: relative;
		// width: 60px;
		width: 30px;
		height: 65px;
		position: absolute;
		right: 100%;
		left: auto;
		cursor: pointer;
		outline: 0!important;

		&.top {
			transform: translateY(30px);
		}

		&:hover,
		&:focus {
			// background-color: $mobile-menu_vertical-accordeon--button-bg-hover;

			.b-mobile-menu__burger-ingredients-middle,
			.b-mobile-menu__burger-ingredients:before,
			.b-mobile-menu__burger-ingredients:after {
				background-color: $mobile-menu_vertical-accordeon--button-color-hover;
			}
		}

		&:active {
			background-color: $mobile-menu_vertical-accordeon--button-bg-active;

			.b-mobile-menu__burger-ingredients-middle,
			.b-mobile-menu__burger-ingredients:before,
			.b-mobile-menu__burger-ingredients:after {
				background-color: $mobile-menu_vertical-accordeon--button-color-active;
			}
		}

		.b-mobile-menu__burger-ingredients {
			position: absolute;
			top: 0;
			bottom: 0;
			left: 0;
			right: 0;
			height: $mobile-menu_vertical-accordeon--bar-thickness;
			width: $mobile-menu_vertical-accordeon--bar-width;
			margin: auto;

			&:before,
			&:after {
				content: '';
				position: absolute;
				display: block;
				left: 0;
				width: 100%;
				height: $mobile-menu_vertical-accordeon--bar-thickness;
				background-color: $mobile-menu_vertical-accordeon--button-color;
				transition-duration: $mobile-menu_vertical-accordeon--transistion-duration, $mobile-menu_vertical-accordeon--transistion-duration;
				transition-delay: $mobile-menu_vertical-accordeon--transistion-duration, 0s;
				will-change: transform;
			}

			&:before {
				top: -$mobile-menu_vertical-accordeon--bar-thickness - $mobile-menu_vertical-accordeon--bar-space;
				/* autoprefixer: off */
				transition-property: top, transform;
			}

			&:after {
				bottom: -$mobile-menu_vertical-accordeon--bar-thickness - $mobile-menu_vertical-accordeon--bar-space;
				/* autoprefixer: off */
				transition-property: bottom, transform;
			}
		}

		.b-mobile-menu__burger-ingredients-middle {
			position: absolute;
			top: 0;
			left: 0;
			height: 100%;
			width: 100%;
			background-color: $mobile-menu_vertical-accordeon--button-color;
			transition: opacity 0s $mobile-menu_vertical-accordeon--transistion-duration;
			will-change: transform;
		}

		&.is-expand {
			position: fixed;
			z-index: 500;

			// .b-mobile-menu__burger-ingredients {
			// 	&:before,
			// 	&:after {
			// 		transition-delay: 0s, $mobile-menu_vertical-accordeon--transistion-duration;
			// 	}

			// 	&:before {
			// 		top: -5.6px;
   //  				width: 24px;
			// 	    transform: translate3d(.63rem,0,0) rotate(45deg) scale(.7,1);
			// 	}

			// 	&:after {
			// 		bottom: -5.6px;
   //  				width: 24px;
			// 		transform: translate3d(.63rem,0,0) rotate(-45deg) scale(.7,1);
			// 	}
			// }
		}
	}

	.b-mobile-menu__burger.top + .b-mobile-menu__panel {
		// margin-top: 30px;
	}

	.b-mobile-menu__panel {
		// background-color: #f8f9fa;
		background-color: #E1F4FD;
		// padding: 20px 10px 20px;
		min-width: 200px;
		min-height: 200px;
		max-height: calc(100vh - 100px);
		overflow-x: hidden;
		overflow-y: auto;
		max-width: 410px;
		transition: margin-top linear .15s;
		overflow-x: hidden;
		overflow-y: auto;
		height: 100%;

		// &-item--menu {
		// 	overflow-x: hidden;
		// 	overflow-y: auto;
		// 	height: 100%;
		// }

		&::-webkit-scrollbar {
			background: rgba(#28AAE1, 0.4);
			width: 3px;
		}

		&::-webkit-scrollbar-thumb {
			// border-radius: 2px;
			background-color: #28AAE1;
			// background-color: rgba(0, 0, 0, 0.4);
		}
	}

	.b-mobile-menu__item {
		display: block;
		vertical-align: top;
		margin-bottom: 15px;
	}

	.b-mobile-menu__item--search {
		padding: 0 20px;
		text-align: center;
		padding-left: 15px;
		padding-right: 15px;

		.search.search--inner .search__input {
			width: 100%;
		}

		.search {
			display: inline-block;
			width: 100%;
		}

		&--menu {
			width: 100%;
		}
	}

	.b-mobile-menu__panel-item--applications {
		display: none;

		@include breakpoint(sm) {
			display: flex;
			align-items: center;
			justify-content: center;
			@include font(600);
			font-size: 14px;
			letter-spacing: -0.01em;
			text-transform: uppercase;
			color: $color-dark-cerulean;
			width: 134px;
			height: 26px;
			border-radius: 15px;
			border: 1px solid $color-dark-cerulean;
			text-decoration: none;
			margin-left: 20px;
			margin-bottom: 10px;

			a {
				font-size: 14px;
				text-decoration: none;
			}

			&:hover,
			&:active,
			&.is-expand {
				color: $color-white;
				background-color: $color-dark-cerulean;
			}

			&:active {
				transform: translateY(1px);
			}
		}
	}

	.b-mobile-menu__panel-item--search {
		display: none;

		@include breakpoint(sm) {
			display: block;

			.b-search__input {
				border: none;
				border-bottom: 1px solid $color-deep-sky-blue;
				height: 23px;
				padding-left: 4px;
				background-color: transparent;
			}

			.b-search__button {
				@include webfont-icon($webfont-icon--search_2);
				@include font-size(23px);
				height: auto;
				width: 25px;
				color: $color-deep-sky-blue;
			}

			.search__additional-buttons {
				display: none;
			}

			.b-search.b-search--inner .b-search__button {
				right: 16px;
			}

			.b-search__input-container {
				padding: 0 39px 0 16px;
			}

			.b-search.b-search--inner .b-search__label {
				left: 20px;
				font-size: 14px;
				letter-spacing: -0.01em;
				color: rgb(0, 55, 123);
			}
		}
	}

	.b-mobile-menu__item--menu {
		width: 100%;
	}

	.b-nav__list {
		.b-nav__list {
			display: none;
		}

		&.is-expand {
			display: block;
		}

		li:last-child {
			margin-bottom: 50px;
		}
	}

	.b-nav__item--lvl1 + .b-nav__item--lvl1 {
		margin-top: 1px;
	}

	.b-nav__link {
		display: block;
		@include rem(padding-top, 5px);
		@include rem(padding-bottom, 5px);
		color: $mobile-menu_vertical-accordeon--link-color;
		text-decoration: none;
		@include font(400, "VTBGroup");
		line-height: 1;
		vertical-align: middle;
		display: inline-block;
		border-radius: 0;
		color: #00377b;
		line-height: 23px;
		font-size: 17px;
		text-decoration: none;
		background-color: transparent;
		padding-right: 36px;
		text-transform: uppercase;

		span {
			line-height: 1.4;
		}

		&--lvl1 {
			pointer-events: none;
		}

		&--lvl2 {
			&:hover {
				// color: $mobile-menu_vertical-accordeon--link-color-hover;
				text-decoration: underline;
			}

			&:active {
				color: $mobile-menu_vertical-accordeon--link-color-active;
			}

			&.current {
				// color: $mobile-menu_vertical-accordeon--link-color-active;
				color: #28AAE1;
			}
		}

	}

	.b-nav__controller {
		position: absolute;
		@include rem(width, 44px);
		display: block;
		top: 0;
		right: 0;
		margin: auto 0;
		bottom: 0;
		cursor: pointer;

		&:before,
		&:after {
			content: '';
			display: block;
			@include rem(width, 2px);
			@include rem(height, 10px);
			position: absolute;
			background-color: $mobile-menu_vertical-accordeon--link-color;
			top: 0;
			left: 0;
			bottom: 0;
			right: 0;
			margin: auto;
			transition: transform 0.2s ease;
			@include rem(border-radius, 5px);
		}

		&:before {
			transform: rotate(-45deg);
			left: -12px;
		}

		&:after {
			transform: rotate(45deg);
		}

		&:hover {
			&:before,
			&:after {
				background-color: $mobile-menu_vertical-accordeon--link-color-hover;
			}
		}

		&:active {
			&:before,
			&:after {
				background-color: $mobile-menu_vertical-accordeon--link-color-active;
			}
		}

		&.is-expand{
			&::before {
				transform: rotate(45deg);
			}

			&::after {
				transform: rotate(-45deg);
			}
		}
	}

	.b-nav__link--lvl1 {
		background-color: $mobile-menu_vertical-accordeon--link-lvl1-bg;
		color: $mobile-menu_vertical-accordeon--link-color;
		padding-left: 15px;
		font-weight: 600;
		@include font-size(20px);
		text-transform: uppercase;
		color: #0E4E96;

		&:hover {
			color: $mobile-menu_vertical-accordeon--link-color-hover;
		}

		&:active {
			color: $mobile-menu_vertical-accordeon--link-color-active;
		}
	}

	.b-nav__link-wrapper {
		position: relative;
	}

	.b-nav__link--lvl2 {
		@include rem(padding-left, 15px);
		background-color: $mobile-menu_vertical-accordeon--link-lvl2-bg;
		@include font-size(16px);
		color: #0E4E96;
	}

	.b-nav__link--lvl3 {
		@include rem(padding-left, 45px);
		background-color: $mobile-menu_vertical-accordeon--link-lvl3-bg;
	}

	.b-nav__link--lvl4 {
		@include rem(padding-left, 60px);
	}

	.b-nav__list--lvl2 {
		margin-bottom: 50px;
	}

	@media (max-width: 1480px) {
		.b-mobile-menu__panel {
			max-width: 270px;
		}
	}

	// @include breakpoint(md) {
		// .b-mobile-menu__panel {
		// 	max-width: 270px;
		// }
	// }


	@media (max-width: 1280px) {
		position: static;
		max-width: none;
		transform: none !important;

		.b-mobile-menu__burger {
			display: block;
			top: 0 !important;
			&.top {
				transform: translateY(0) !important;
			}
		}

		.b-mobile-menu__header {
			background: #E1F4FD;
			// display: block;
			height: 64px;
			padding: 0 45px 0 0;
			transition: margin-top linear .15s;
			position: absolute;
		    top: 0px;
		    left: 0px;
		    width: 100%;

			&--text {
				padding: 22px 20px;
				color: #00377B;
			    text-transform: uppercase;
			    font-size: 14px;
			    line-height: 18px;
			    font-weight: 600;
			}
		}


		.b-mobile-menu__panel-item {
			&--mobile {
				display: flex;
				padding: 3px 20px 0;
				margin-bottom: 14px;
				position: relative;
				justify-content: space-between;
				align-items: center;

				.l-buttons-area {

					&__dc,
					&__search,
					&__lang,
					&__pdf {
						display: block;
					}

					// &__pdf,
					// &__lang {
					// 	margin-left: 18px;
					// }
				}
			}
		}

		.b-mobile-menu__panel {
			display: none;
			position: fixed;
			// top: 0;
			top: 65px;
			max-width: 100%;
			// max-height: 100%;
			max-height: calc(100% - 65px);
			left: 0;
			width: 100%;
			// height: calc(100vh - 69px);
			height: 100%;
			overflow-x: hidden;
			overflow-y: auto;
			-webkit-overflow-scrolling: touch;
			padding-bottom: 10px;
			padding-top: 10px;

			&--header {
				display: block;
			}
		}

		.b-mobile-menu__burger.top + .b-mobile-menu__panel {
			margin-top: 0;
		}

		.b-mobile-menu__burger {
			position: relative;
			background-color: transparent;
			right: 0;

			&.is-expand {
				position: relative;

				.b-mobile-menu__burger-ingredients-middle {
					background-color: transparent;
				}

				.b-mobile-menu__burger-ingredients {

					&:before {
						top: 1px;
						transform: rotate(45deg);
					}

					&:after {
						bottom: -1px;
						transform: rotate(-45deg);
					}
				}
			}
		}

		.b-nav {
			&__link {
				padding-bottom: 0px;
				&--lvl1 {
					padding-left: 20px;
				}

				&--lvl2 {
					padding-left: 36px;
					padding-top: 10px;
				}
			}

			&__controller {

			}
		}
	}
}
