.header-1 {
	display: block;
	@include font-size(40px);
	line-height: 49px;
	@include font(700, 'VTBGroup');
	color: $color-dark-cerulean;
	margin: 0 0 40px;
	// padding-bottom: 8px;
	// border-bottom: 1px solid $color-hit-gray;
	text-transform: uppercase;

	&:after {
		content: '';
		display: block;
		width: 140px;
		height: 3px;
		background-color: $color-deep-sky-blue;
	}

	@include breakpoint(xs) {
		@include font-size(20px);
		line-height: 25px;
		margin: 0 0 27px;
	}
}

h1 {
	@extend .header-1;
}

.header-2 {
	@include font(700, 'VTBGroupCond');
	@include font-size(32px);
	line-height: 39px;
	color: $color-dark-cerulean;
	margin: 0 0 19px;

	@if ($responsive) {
		@include breakpoint(sm) {
			line-height: 1;
		}

		@include breakpoint(xs) {
			@include font-size(20px);
			line-height: 24px;
			margin: 0 0 22px;

		}
	}

	.report-type--csr & {
		margin: 40px 0 19px;

		@if ($responsive) {
			@include breakpoint(xs) {
				margin: 40px 0 22px;
			}
		}
	}
	&-sub {
		&:after {
			content: '';
			display: block;
			width: 140px;
			height: 3px;
			background-color: $color-deep-sky-blue;
		}
	}
}

h2 {
	@extend .header-2;
}

.header-3 {
	@include font(600, 'VTBGroup');
	@include font-size(26px);
	line-height: 36px;
	color: $color-deep-sky-blue;
	margin: 0 0 21px;

	@include breakpoint(xs) {
		@include font-size(17px);
		line-height: 24px;
		margin: 0 0 14px;
	}

	.report-type--csr & {
		margin: 40px 0 21px;

		@if ($responsive) {
			@include breakpoint(xs) {
				margin: 40px 0 14px;
			}
		}
	}
}

h3 {
	@extend .header-3;
}

.header-4 {
	@include font(600, 'VTBGroupCond');
	@include font-size(22px);
	line-height: 31px;
	color: $color-dark-cerulean;
	margin: 0 0 16px;

	@include breakpoint(xs) {
		@include font-size(17px);
		line-height: 24px;
		margin: 0 0 14px;
	}

}

h4 {
	@extend .header-4;
}

.header-5 {
	@include font(700, 'VTBGroup');
	@include font-size(17px);
	line-height: 24px;
	color: $color-dark-cerulean;
	margin: 0 0 10px;

	@include breakpoint(xs) {
		@include font-size(15px);
		line-height: 21px;
		margin: 0 0 15px;
	}
}

h5 {
	@extend .header-5;
}

.header-6 {
	@include font-size(16px);
	font-weight: bold;
	margin-bottom: 1.2em;
	color: $color-deep-sky-blue;
}

h6 {
	@extend .header-6;
}

.header-icon {
	display: flex;
	align-items: center;
	margin-bottom: 0.83em;

	&__icon {
		margin-right: 12px;
	}

	&__title {
		margin: 0;
	}
}

.h_special {
	font-weight: bold;
}

.header-fn {
	color: $color-black;
	font-weight: bold;
	@include font-size(18px);
}

.header-on-plate {
	display: inline-block;
	padding: 0 15px;
	background-color: #00AEEF;
	font-weight: 600;
	font-size: 18px;
}
